$(document).on('ajax:success', '.dropdown a', function() {
  $(this).closest('.dropdown').find('.dropdown-toggle').dropdown('toggle');
});

$(document).on('change', 'input.selectized', function() {
  const parsley = $(this).parsley();
  if (parsley && parsley.validate) {
    parsley.validate();
  }
});

$(document).on('click', 'input.text-select', function() {
  this.select();
});

// remove tooltips if elements get removed from dom
$(document).on('ajax:before', 'a[data-toggle="tooltip"]', function() {
  $(this).tooltip('hide');
});