window.ParsleyConfig = {
  errorClass: 'is-invalid',
  successClass: 'is-valid',
  excluded: 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], .selectize-input input, .skip, .never-validate, [disabled]',
  classHandler(ParsleyField) {
    const $field = ParsleyField.$element;
    if ($field.hasClass('selectized')) {
      return $field.siblings('.selectize-control');
    } else {
      return $field;
    }
  },

  errorsContainer(ParsleyField) {
    const {
      $element
    } = ParsleyField;
    if ($element.hasClass('selectized')) {
      return $element.parent();
    } else if ($element.hasClass('inline-error')) {
      return $element.closest('.btn-group');
    }
  },
  errorsWrapper: '<div class="invalid-feedback"></div>',
  errorTemplate: '<div></div>',
  i18n: {
    en: {
      lineitem: 'Select a quantity.',
      creditcard: 'Please enter a valid credit card number.',
      fullname: 'Please enter your full name.'
    }
  },
  validators: {
    fullname: {
      fn(value, requirements) {
        const name = value.split(' ');
        if ((name.length < 2) || (name[1].length < 2) || (name[0].length < 2)) {
          return false;
        } else {
          return true;
        }
      }
    },
    creditcard: {
      fn(value, requirements) {
        let len = value.length;
        let mul = 0;
        const prodArr = [
          [0,1,2,3,4,5,6,7,8,9],
          [0,2,4,6,8,1,3,5,7,9]
        ];
        let sum = 0;
        while (len--) {
          sum += prodArr[mul][parseInt(value.charAt(len), 10)];
          mul ^= 1;
        }
        return ((sum % 10) === 0) && (sum > 0);
      }
    }
  }
};

require('parsleyjs');
require('parsleyjs/dist/i18n/fr.js');
require('parsleyjs/dist/i18n/de.js');
require('parsleyjs/dist/i18n/es.js');
