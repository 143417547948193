import intlTelInput from 'intl-tel-input';

window.Parsley.on('form:error', function(form) {
  if (form.isValid()) { return; }

  return (() => {
    const result = [];
    for (let field of Array.from(form.fields)) {
      const $field_group = field.$element.closest('.form-group');
      if (!field.isValid() && !$field_group.is(':visible')) {
        var field_name;
        const $parent_tab = $field_group.closest('.tab-pane');
        if ($parent_tab.length) {
          $('a[href="#' + $parent_tab.attr('id') + '"]').tab('show');
        }
        const $parent_panels = $field_group.parents('.collapse');
        $parent_panels.each((index, panel) => $(panel).collapse('show'));
        const $label_field = $field_group.find('label');
        if ($label_field.length) {
          field_name = $label_field.html();
        } else {
          field_name = 'Field';
        }
        const error = window.Parsley.getErrorMessage(field.validationResult[0].assert);
        if (field_name) {
          result.push(notify('error', field_name + ': ' + error));
        } else {
          result.push(undefined);
        }
      } else {
        result.push(undefined);
      }
    }
    return result;
  })();
});

window.rebuild_parsley = $forms => $forms.each(function(index, form) {
  const $form = $(form);
  if ($form.parsley()) { $form.parsley().destroy(); }
  return $form.parsley();
});

$(document).on('ajax:success', '.ajax-msg', function(event) {
  const data = event.detail[0];
  let {
    message
  } = data;
  if (!message) { message = $(this).data('message'); }
  return notify('success', message);
});

$(document).on('click', '.change-password-btn', function() {
  $('.password-fields').toggleClass('d-none');
  $('.password-fields input').val('');
  return false;
});

$(document).on('click', '.facebook-login-button', function() {
  facebook_login(function(response) {
    if (response.status === 'connected') {
      return facebook_check_state();
    }
  }
  , $(this).data('reauth'));
  return false;
});

window.add_selectize_fields = $container => $container.find('input.add-selectize, select.add-selectize').not('.selectized').each(function(index, el) {
  return $(this).selectize();
});

$(document).on('countrychange', 'input', function(e, country_data) {
  const $country_field = $(this).closest('.phone').find('input.intl-country');
  $country_field.val(country_data.iso2);
});

window.init_phones = function($container) {
  const $phone_fields = $container.find('.intl-phone');
  $phone_fields.each(function(index, field) {
    const country = $(field).data('country');
    const iti = intlTelInput(field, {
      initialCountry: country,
      //hiddenInput: name
      preferredCountries: ['us', 'gb', 'ca', 'au'],
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/' + intlTelInputGlobals.version + '/js/utils.js'

    });
    field.addEventListener('countrychange', function(e) {
      $(field).closest('.form-group').find('.intl-country').val(iti.getSelectedCountryData().iso2);
    });
  });
};

$(document).on('countrychange', '.intl-phone', function(e, country_data) {
  const code = country_data.iso2;
  return $(this).closest('.form-group').find('input[type="hidden"]').val(code);
});

$(document).on('change', 'select.country-select', function() {
  const $country_field = $(this);
  const country_id = $country_field.val();

  if (!!!country_id) { return; }

  const state_select = $country_field.data('state-target');
  if (!state_select) { return; }
  const $state_select = $country_field.parents('.form-group').siblings('.form-group').find(state_select);
  if (!$state_select.length) { return; }
  const {
    selectize
  } = $state_select[0];
  if (selectize) {
    selectize.clearOptions();
    selectize.disable();
    selectize.load(callback => $.ajax({
      type: 'GET',
      url: '/api/frontend/countries/' + country_id + '/states',
      error() {
        alert('error loading states. please refresh and try again.');
      },
      success(states) {
        const options = [];
        for (let state of Array.from(states)) {
          options.push({value: state.id, text: state.name});
        }
        selectize.enable();
        selectize.setValue(null);
        selectize.clearOptions();
        selectize.clear();
        callback(options);
      }
    }));
  } else {
    $state_select.empty();
    $.ajax({
      type: 'GET',
      url: '/api/frontend/countries/' + country_id + '/states',
      error() {
        alert('error loading states. please refresh and try again.');
      },
      success(states) {
        let string = '<option value disabled="disabled" selected="selected">Choose...</option>';
        for (let state of Array.from(states)) {
          string += '<option data-abbr="' + state.abbr + '" value="' + state.id + '">' + state.name + '</option>';
        }
        $state_select.append(string);
      }
    });
  }
});


// Rails ujs
$(document).on('ajax:error', event => handle_ajax_error(event, event.detail[2]));

// jquery
$(document).ajaxError((event, jqxhr, settings, thrownError) => handle_ajax_error(event, jqxhr));

const json_response_from_error = function(xhr) {
  try {
    return JSON.parse(xhr.responseText);
  } catch (e) {}
};

window.handle_ajax_error = function(error, xhr) {
  const error_statuses = [422, 400, 401, 403, 404];
  if (Array.from(error_statuses).includes(xhr.status)) {
    const response = json_response_from_error(xhr);
    if (!response) { return; }
    error = response['message'] || response['error'] || response['errors'];
    notify('error', error);

  } else if (xhr.status === 500) {
    notify('error', 'Error communicating with the server. We have been notified.');
  }
  if (xhr.status === 401) {
    notify('warning', 'Authentication Failed.');
  }
  if (xhr.status === 403) {
    return window.location = '/access-denied';
  }
};