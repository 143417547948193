import { mapFieldsToButtons } from "./toolbars/functions.js";
import { fields as attendee_fields } from "./toolbars/ticket.js";
import { fields as contact_fields } from "./toolbars/contact.js";

function InitializeToolbar(trix, toolbar) {
  var dropdownButton = document.createElement("button");
  dropdownButton.setAttribute("type", "button");
  dropdownButton.setAttribute("class", "trix-button");
  dropdownButton.setAttribute("data-toggle", "dropdown");
  var dropdownMenu = document.createElement("div");
  dropdownMenu.setAttribute("class", "dropdown-menu");

  switch (toolbar) {
    case "contact":
      dropdownButton.innerText = "Contact";
      dropdownButton.setAttribute("title", "Insert contact field");
      mapFieldsToButtons(trix, dropdownMenu, contact_fields);

      break;
    case "ticket":
      dropdownButton.innerText = "Attendee";
      dropdownButton.setAttribute("title", "Insert attendee field");
      mapFieldsToButtons(trix, dropdownMenu, attendee_fields);

      break;
  }
  var buttonGroup = trix.toolbarElement.querySelector(
    ".trix-button-group--text-tools"
  );

  buttonGroup.appendChild(dropdownButton);
  buttonGroup.appendChild(dropdownMenu);
}

addEventListener("trix-initialize", function(event) {
  var trix = event.target;

  var toolbar = trix.getAttribute("data-toolbar");
  if (toolbar != null) {
    InitializeToolbar(trix, toolbar);
  }
});

// addEventListener("trix-initialize", event => {
//   const { toolbarElement } = event.target
//   const input = toolbarElement.querySelector("input[name=href]")
//   // Change the input type from "url" to "text" to allow liquid variables
//   input.type = "text"
// })

// document.addEventListener("trix-action-invoke", function(event) {
//   if (event.actionName === "x-insert-field") {
//     var field = event;
//     //event.target.editor.insertString(field);
//   }
// });
