import consumer from "./consumer";
import Rails from "@rails/ujs";

document.addEventListener("DOMContentLoaded", () => {

  if (window.user_token) {
    subscribeToUserChannel()
  }
});

function subscribeToUserChannel() {
  consumer.subscriptions.create("UserChannel", {
    received: function(data) {
      var event = data.event;
      switch (event) {
        case "ipad_lined":
          notify("success", "iPad Connected!");
          if ($("body.controller-onboard").length) {
            window.location.href = next_step_link;
          }
          break;
        case "set_progress_info":
          set_progress_info(data);
          break;
        case "row_imported":
          row_imported(data);
          break;
        case "job_finished":
          job_finished(data);
          break;
        case "notify":
          notify(data.type, data.message);
          break;
        case "export_complete":
          var export_id = data.export_id;
          if (export_id) {
            var button = $('form[data-id="' + export_id + '"]').find(
              ".export-button"
            )[0];
            if (button) {
              Rails.enableElement(button);
            }
          } else {
            $(".export-button").each(function(index, button) {
              Rails.enableElement(button);
            });
          }
          break;
      }
    }
  });
}

function set_progress_info(data) {
  var $modal, $progress_bar;
  $modal = $("#importing-progress-modal");
  $progress_bar = $modal.find(".row-import-bar");
  $modal.find(".title").html(data.title);
  $modal.find(".description").html(data.description);
  $modal.find(".tip").html(data.tip);
  $modal.modal("show");
}

function job_finished(data) {
  var $modal, $progress_bar;
  $modal = $("#importing-progress-modal");
  $progress_bar = $modal.find(".row-import-bar");
  if (data.message) {
    notify("success", data.message);
  } else {
    notify("success", data.count + " items were processed.");
  }
  setTimeout(function() {
    $progress_bar.data("width", 0);
    $progress_bar.css("width", "0%");
    $modal.modal("hide");
    TableRefreshVisible();
  }, 400);
}

function row_imported(data) {
  var $modal, $progress_bar, current_width, width;
  $modal = $("#importing-progress-modal");
  $progress_bar = $modal.find(".row-import-bar");
  $modal.modal("show");
  width = data.percent;
  current_width = $progress_bar.data("width");
  $progress_bar.data("width", width);
  $progress_bar.css("width", width + "%");
}
