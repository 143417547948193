const selector = 'select.import-header-mapping';
$(document).on('change', selector, function(event) {
  const $target = $(this);
  const {
    selectize
  } = this;
  const option = selectize.options[selectize.getValue()];
  if (!option) { return; }
  const $control = $target.siblings('.selectize-control');
  return $control.find('.selectize-input .item').html(option.optgroup + ' - ' + option.text);
});

$(() => // add the opt group names to the pre-selected column mapping selects
$(selector).trigger('change'));
