import Sortable from "sortablejs/Sortable";

const export_form = function() {
  var form = document.getElementById('export-template-fields-active');
  var form2 = document.getElementById('export-template-fields-inactive');

  if (form == null) {
    return
  }
  Sortable.create(form, { group: 'report-template'})
  Sortable.create(form2, { group: 'report-template'})
}

$(document).ready(export_form);

$(document).on('submit', 'form.report-template-form', function(e) {
  var sortable = Sortable.get(document.getElementById('export-template-fields-active'));
  $(this).find('input.columns').val(sortable.toArray());
});