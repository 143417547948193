const set_theme = function(theme = null, $body = null) {
  if (!theme) { theme = localStorage.getItem('theme'); }
  if (!$body) { $body = $('body'); }
  if (theme === 'dark') {
    return $body.addClass('dark');
  } else {
    return $body.removeClass('dark');
  }
};

$(document).on('show.bs.modal', function(e) {
  set_theme(null, $(this).find('.modal-content'));
});

$(document).ready(set_theme);

$(document).on('click', 'a[data-theme]', function(e) {
  e.preventDefault();
  const theme = $(this).data('theme');
  localStorage.setItem('theme', theme);
  set_theme(theme);
});
