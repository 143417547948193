/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const get_data = function(control, url, query, callback, params) {
  if (params == null) { params = {}; }
  const value = control.$input.data('value');
  if (!url) { url = control.$input.data('url'); }
  params.value = value;
  params.q = query;
  $.ajax({
    url,
    dataType: 'json',
    type: 'GET',
    data: params,
    error() {
      callback();
    },
    success: data => {
      if (query.length) {
        for (let d of Array.from(data)) {
          d.query = query;
        }
      }

      return callback(data);
    }
  });
};

const get_modal = function(url, params, callback) {
  $.ajax({
    url,
    type: 'GET',
    data: params,
    dataType: 'script',
    success(data) {
      const $modal = $(VisibleModalID);
      return prepare_modal($modal, callback);
    }
  });
};

const select_endpoint = function(model) {
  return `/backend/companies/${company_id}/selects${model}/`
};

var prepare_modal = function($modal, callback) {
  const $form = $modal.find('form');
  $form[0].setAttribute('data-type', 'json');
  $form[0].setAttribute('data-remote', 'true');

  $form.one('ajax:success', event => {
    const data = event.detail[0];
    callback(data);
    return $modal.modal('toggle');
  });
  return $modal.one('hidden.bs.modal', () => callback(null));
};

$(document).on('click', '.selectize-create-button', function(e) {
  e.preventDefault();
  const {
    selectize
  } = $(this).closest('.input-group').find('input.selectized')[0];
  const name = $(this).data('name');
  selectize.createItem(name);

});

const default_options = {
  preload: 'focus',
  create: false,
  maxItems: 1,
  valueField: 'id',
  labelField: 'label',
  searchField: ['label'],
  load(query, callback) {
    return get_data(this, null, query, callback);
  }
};

const PassbookTemplateOptions = {
  load(query, callback) {
    return get_data(this, select_endpoint('/passbook_templates'), query, callback);
  }
};

const DeviceOptions = {
  load(query, callback) {
    return get_data(this, select_endpoint('/devices'), query, callback);
  }
};

const EmployeeOptions = {
  load(query, callback) {
    var url = this.$input.data('url');
    if (url == null) {
      url = select_endpoint('/employees')
    }
    return get_data(this, url, query, callback);
  }
};

const SeatChartOptions = {};
const EventSeatOptions = {
  template: 'no-img user',
  maxItems: null,
  valueField: 'id',
  labelField: 'key',
  searchField: ['key'],
  render: {
    option(item, escape) {
      let html = '';
      html += '<div>';
      html += '<span class="title">';
      html += '<span class="name">' + escape(item.label) + ' ' + escape(item.name) + '</span>';
      html += '</span>';
      html += '<span class="description">';
      html += escape(item.key);
      html += '</span>';
      html += '</div>';
      return html;
    }
  },
  load(query, callback) {
    const url = this.$input.data('url');
    return get_data(this, url, query, callback);
  }
};

const TicketOptions = {
  load(query, callback) {
    const event_ids = this.$input.data('event_ids');
    return get_data(this, null, query, callback, {event_ids});
  }
};

const OrderChannelOptions = {
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/order_channels');
    return get_data(this, url, query, callback);
  }
};

const ShippingStateOptions = {
  load(query, callback) {
    return get_data(this, '/api/frontend/states/', query, callback);
  }
};

const ShippingCountryOptions = {
  load(query, callback) {
    return get_data(this, '/api/frontend/countries/', query, callback);
  }
};

const CalendarOptions = {
  preload: 'focus',
  create(name, callback) {
    if (!!!name) { return false; }
    $.ajax({
      url: select_endpoint('/calendars'),
      type: 'POST',
      data: {
        calendar: {
          name
        }
      },
      success(data) {
        return callback({id: data.id, name: data.name});
      }
    });
  },
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/calendars');
    return get_data(this, url, query, callback);
  }
};

const CategoryOptions = {
  preload: 'focus',
  create(name, callback) {
    if (!!!name) { return false; }
    $.ajax({
      url: select_endpoint('/categories'),
      type: 'POST',
      data: {
        category: {
          name
        }
      },
      success(data) {
        return callback({id: data.id, name: data.name});
      }
    });
  },
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/categories');
    return get_data(this, url, query, callback);
  }
};

const ServiceFeeCategoryOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/service_fee_categories');
    return get_data(this, url, query, callback);
  }
};

const ServiceFeeRateOptions = {
  preload: 'focus',
  load(query, callback) {
    const url = select_endpoint('/service_fee_rates');
    return get_data(this, url, query, callback);
  }
};

const TaxRateOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/tax_rates');
    return get_data(this, url, query, callback);
  }
};

const TaxCategoryOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/tax_categories');
    return get_data(this, url, query, callback);
  }
};

const EmailLayoutTemplateOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/email_layout_templates');
    return get_data(this, url, query, callback);
  }
};

const ZoneOptions = {
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/zones');
    return get_data(this, url, query, callback);
  }
};

const ListSettingOptions = {
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/list_settings');
    return get_data(this, url, query, callback);
  }
};

const TicketTemplateOptions = {
  preload: 'focus',
  load(query, callback) {
    const url = select_endpoint('/ticket_templates');
    return get_data(this, url, query, callback);
  }
};

const EventTicketTypeOptions = {
  load(query, callback) {
    const event_ids = event_ids_for_input(this.$input);
    const url = select_endpoint('/event_ticket_types');
    return get_data(this, url, query, callback, { event_ids });
  },
  onChange(value) {

    const item = this.options[this.getValue()];
    if (item) {
      this.$input.data('event-ids', item.event_id)
    }

    const related = this.$input.closest('form').find('input.list-select');
    related.each(function(index, input) {
      if (value) {
        if (!$(input).data('force-disabled')) { input.selectize.enable(); }
        if (input.selectize && !$(input).data('value')) {
          input.selectize.clearOptions();
          return input.selectize.onSearchChange('');
        }
      } else {
        return input.selectize.disable();
      }
    });

  },
};

const MembershipPlanOptions = {
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/membership_plans');
    return get_data(this, url, query, callback);
  }
};

const ContactQueryOptions = {
  create(name, callback) {
    const url = '/company/' + company_id + '/contact_queries/new';
    const params = {
      contact_query: {
        name
      }
    };
    return get_modal(url, params, callback);
  },
  load(query, callback) {
    const url = select_endpoint('/contact_queries');
    return get_data(this, url, query, callback);
  }
};

const TagOptions = {
  create(name, callback) {
    if (!!!name) { return false; }
    $.ajax({
      url: select_endpoint('/tags'),
      type: 'POST',
      data: {
        tag: {
          name
        }
      },
      success(data) {
        return callback({id: data.id, name: data.name});
      }
    });
  },
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/tags');
    return get_data(this, url, query, callback);
  }
};

const StationOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    var url = this.$input.data('url');
    if (url == null) {
      url = select_endpoint('/stations');
    }
    return get_data(this, url, query, callback);
  }
};

const ImportOptions = {
  sortField: [[]],
  load(query, callback) {
    let url = this.$input.data('url');
    if (!url) { url = select_endpoint('/imports'); }
    return get_data(this, url, query, callback);
  }
};

const TicketTypeOptions = {
  create(name, callback) {
    const url = '/company/' + company_id + '/ticket_types/new';
    const params = {
      ticket_type: {
        name
      }
    };
    return get_modal(url, params, callback);
  },
  load(query, callback) {
    const el = this.$input.data('target');
    const $el = this.$input.closest('form').find(el);
    let event_ids = $el.val();
    if (!event_ids) { event_ids = $el.data('value'); }
    if (!event_ids) { event_ids = [this.$input.data('event-id')]; }
    const url = select_endpoint('/ticket_types');
    return get_data(this, url, query, callback, {event_ids});
  }
};

const PaymentOptions = {};

const RefundReasonOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/refund_reasons');
    return get_data(this, url, query, callback);
  }
};

const SitePageOptions = {
  preload: 'focus',
  load(query, callback) {
    const url = select_endpoint('/site_pages');
    return get_data(this, url, query, callback);
  }
};

const CheckinActionTemplateOptions = {
  preload: 'focus',
  create(name, callback) {
    const url = '/company/' + company_id + '/checkin_action_templates/new';
    const params = {
      checkin_action_template: {
        name
      }
    };
    return get_modal(url, params, callback);
  },
  load(query, callback) {
    const url = select_endpoint('/checkin_action_templates');
    return get_data(this, url, query, callback);
  }
};

const OrderFormOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/order_forms');
    return get_data(this, url, query, callback);
  }
};

const TallyTemplateOptions = {
  preload: 'focus',
  create(name, callback) {
    const url = '/company/' + company_id + '/tally_templates/new';
    const params = {
      tally_template: {
        name
      }
    };
    return get_modal(url, params, callback);
  },
  load(query, callback) {
    const url = select_endpoint('/tally_templates');
    return get_data(this, url, query, callback);
  }
};

const PickupTemplateOptions = {
  preload: 'focus',
  create(name, callback) {
    const url = '/company/' + company_id + '/pickup_templates/new';
    const params = {
      pickup_template: {
        name
      }
    };
    return get_modal(url, params, callback);
  },
  load(query, callback) {
    const url = select_endpoint('/pickup_templates');
    return get_data(this, url, query, callback);
  }
};

const StationTemplateOptions = {
  preload: 'focus',
  create(name, callback) {
    const url = '/company/' + company_id + '/station_templates/new';
    const params = {
      station_template: {
        name
      }
    };
    return get_modal(url, params, callback);
  },
  load(query, callback) {
    const url = select_endpoint('/station_templates');
    return get_data(this, url, query, callback);
  }
};

const ProductOptions = {
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/products');
    return get_data(this, url, query, callback);
  }
};

const TallyTypeOptions = {
  create(name, callback) {
    const url = '/company/' + company_id + '/tally_types/new';
    const params = {
      tally_type: {
        name
      }
    };
    return get_modal(url, params, callback);
  },
  load(query, callback) {
    const url = select_endpoint('/tally_types');
    return get_data(this, url, query, callback);
  }
};

const TaxonOptions = {
  create(name, callback) {
    if (!!!name) { return false; }
    $.ajax({
      url: select_endpoint('/taxonomies'),
      type: 'POST',
      data: {
        taxonomy: {
          name
        }
      },
      success(data) {
        return callback({id: data.id, label: data.label});
      }
    });
  },
  load(query, callback) {
    const url = select_endpoint('/taxons');
    return get_data(this, url, query, callback);
  }
};

const OptionValueOptions = {
  create(name, callback) {
    if (!!!name) { return false; }
    const option_type_id = this.$input.data('option-type-id');
    $.ajax({
      url: select_endpoint('/option_types/' + option_type_id + '/option_values'),
      type: 'POST',
      data: {
        option_value: {
          presentation: name
        }
      },
      error(xhr, status) {
        return handle_ajax_error(status, xhr);
      },
      success(data) {
        return callback({id: data.id, label: data.label});
      }
    });
  },
  load(query, callback) {
    const option_type_id = this.$input.data('option-type-id');
    if (!option_type_id) {
      callback();
      return;
    }
    const url = select_endpoint('/option_types/' + option_type_id + '/option_values');
    return get_data(this, url, query, callback);
  }
};

const PermanentListOptions = {
  preload: 'focus',
  create(name, callback) {
    if (!!!name) { return false; }

    const url = '/company/' + company_id + '/permanent_lists/new';
    const params =
      {name};
    return get_modal(url, params, callback);
  },

  load(query, callback) {
    const url = select_endpoint('/permanent_lists');
    return get_data(this, url, query, callback);
  }
};

const OptionTypeOptions = {
  create(name, callback) {
    if (!!!name) { return false; }
    $.ajax({
      url: select_endpoint('/option_types'),
      type: 'POST',
      data: {
        option_type: {
          presentation: name
        }
      },
      success(data) {
        return callback({id: data.id, label: data.label});
      }
    });
  },
  onChange(value) {
    const {
      $input
    } = this;
    const $inputs = $input.parents('form').find('input.option-type-select');
    const control = this;
    $inputs.each(function(index, select) {
      const $select = $(select);
      if (($input[0] !== select) && ($select.val() === value)) {
        notify('info', 'That option has already been added.');
        control.setValue(null);
        return;
      }
    });

    const $option_value_field = this.$input.parents('.nested-fields').find('input.option-value-select');
    if ($option_value_field.length) {
      const {
        selectize
      } = $option_value_field[0];
      if (selectize) {
        selectize.enable();
        selectize.clearOptions();
        selectize.onSearchChange('');
      }
      return $option_value_field.data('option-type-id', value);
    }
  },

  load(query, callback) {
    const url = select_endpoint('/option_types');
    return get_data(this, url, query, callback);
  }
};

const TicketFieldOptionOptions = {
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const $ticket_field = this.$input.closest('.nested-fields').find('input.ticket-field-select');
    let ticket_field_id = $ticket_field.data('value');
    if (!ticket_field_id) { ticket_field_id = $ticket_field.val(); }
    if (!ticket_field_id) {
      callback(null);
      return;
    }

    const url = select_endpoint('/ticket_fields/' + ticket_field_id + '/ticket_field_options');
    return get_data(this, url, query, callback);
  }
};

const TicketFieldOptions = {
  create(name, callback) {
    const url = '/company/' + company_id + '/ticket_fields/new';
    const params = {
      ticket_field: {
        name: name
      }
    };
    return get_modal(url, params, callback);
  },
  onChange(value) {
    if (!!!value) { return; }
    const $container = this.$input.closest('.nested-fields');
    if (!$container.length) { return; }
    const ticket_field = this.options[value];
    if (ticket_field.choose) {
      $container.find('.ticket-field-options-container').removeClass('hidden');
      $container.find('.ticket-field-value-container').addClass('hidden');
      return $container.find('input.ticket-field-option-select').each(function(index, input) {
        if (input.selectize) {
          input.selectize.clearOptions();
          return input.selectize.onSearchChange('');
        }
      });
    } else {
      $container.find('.ticket-field-options-container').addClass('hidden');
      return $container.find('.ticket-field-value-container').removeClass('hidden');
    }
  },
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/ticket_fields');
    return get_data(this, url, query, callback);
  }
};

const EventSessionOptions = {
  preload: 'focus',
  create: (name, callback) => {
    const url = '/company/' + company_id + '/events/new';
    const params = {
      event: {
        type: 'Event::Parent',
        name: name,
        starts_at: null,
        ends_at: null
      }
    };
    return get_modal(url, params, callback);
  },
  labelField: 'name',
  searchField: ['name'],
  sortField: ['name'],
  onChange(value) {
    const $table = $('.shared-ticket-fields');
    if (!$table.length) { return; }
    if (!value) {
      $table.find('.nested-fields').remove();
      return;
    }

    // Dont reload the list when we are refreshing the page due to validation errors
    if (!$table.closest('form').find('.validation-errors').length) {
      return $.ajax({
        url: '/company/' + company_id + '/session_forms/update_ticket_tiers',
        data: {
          event_id: value
        }
      });
    }
  },

  render: {
    option(event, escape) {
      let html = '';
      html += '<div>';
      html += '<span class="title">';
      html += '<span class="name">' + escape(event.name) + '</span>';

      html += '</span>';

      html += '<span class="description">';
      const cats = event.categories.map(category => escape(category.name));
      html += cats.join('<br />');

      html += '</span>';

      if (event.schedules && event.schedules.length) {
        html += '<span class="description">';
        const schedules = event.schedules.map(schedule => escape(schedule));
        html += schedules.join('<br />');

        html += '</span>';
      }

      html += '</div>';
      return html;
    }
  }
};

const EventOptions = {
  template: 'no-img user',
  preload: 'focus',
  labelField: ['name'],
  searchField: ['slug', 'name_with_date'],
  sortField: [
    {
      field: 'start_date',
      direction: 'asc'
    }
  ],
  render: {
    option(event, escape) {
      let html = '';
      html += '<div>';
      html += '<span class="title">';
      html += '<span class="name">' + escape(event.name) + '</span>';
      html += '</span>';

      html += '<span class="description">';
      html += escape(event.venue.name);
      html += '</span>';

      html += '<span class="description">';
      html += escape( event.date + ' ' + event.time );
      html += '</span>';

      html += '</div>';
      return html;
    }
  },
  onChange(value) {
    const related = this.$input.closest('form').find('input.list-select, input.ticket-type-select');
    related.each(function(index, input) {
      if (value) {
        if (!$(input).data('force-disabled')) { input.selectize.enable(); }// == true
        if (input.selectize && !$(input).data('value')) {
          input.selectize.clearOptions();
          return input.selectize.onSearchChange('');
        }
      } else {
        return input.selectize.disable();
      }
    });

  },
  load(query, callback) {
    const parent_event_id = this.$input.data('parent-event-id');
    const venue_id = this.$input.data('venue-id');
    const date = this.$input.data('date');
    return get_data(this, null, query, callback, {parent_event_id, venue_id, date});
  }
};


const ContactOptions = {
  template: 'user',
  theme: 'user',
  valueField: 'id',
  labelField: 'name',
  searchField: ['barcode', 'name', 'email', 'phone_number', 'query'],
  create(input, callback) {
    if (!!!input) { return; }

    const contact = {phone_attributes: {number: ''}};
    if (/@/.test(input)) {
      contact.email = input;
    } else if (/[0-9.]/.test(input)) {
      if (input.length > 8) {
        contact.phone_attributes.number = input;
      } else {
        contact.barcode = input;
      }
    } else {
      contact.name = input;
    }

    const url = '/company/' + company_id + '/contacts/new';
    const params =
      {contact};
    return get_modal(url, params, callback);
  },

  render: {
    option(contact, escape) {
      let html = '';
      html += '<div>';
      if (contact.photo_url) {
        html += '<img src="' + escape(contact.photo_url) + '" />';
      }
      html += '<span class="title">';
      html += '<span class="name">' + escape(contact.name) + '</span>';
      html += '</span>';
      html += '<span class="description">';
      const email = contact.email || 'No Email';
      const phone = contact.phone_number || 'No Phone #';
      html += escape(email) + ' / ' + escape(phone) + ' / ' + (contact.barcode || 'No Member ID') + '</span>';
      html += '</div>';
      return html;
    }
  },
  load(query, callback) {
    const url = '/backend/companies/' + company_id + '/selects/contacts';
    return get_data(this, url, query, callback);
  }
};

const VenueOptions = {
  template: 'no-img user',
  preload: 'focus',
  searchField: ['name'],
  labelField: ['name'],
  render: {
    option(venue, escape) {
      let html = '';
      html += '<div>';
      html += '<span class="title">';
      html += '<span class="name">' + escape(venue.name) + '</span>';
      html += '</span>';

      html += '<span class="description">';
      html += escape(venue.time_zone);
      html += '</span>';

      html += '</div>';
      return html;
    }
  },
  onChange(value) {
    const $form = this.$input.closest('form');
    if ($form.hasClass('dt-search-form')) { return; }
    const $time_zone_field = $form.find('input[name="time_zone"]');
    const item = this.options[this.getValue()];
    if (!item) { return; } // removed from field
    $time_zone_field.val(item.time_zone);
    update_time_zone_inputs($form, item.time_zone);
  },
  create: (name, callback) => {
    const url = '/company/' + company_id + '/venues/new';
    const params = {
      venue: {
        name
      }
    };
    return get_modal(url, params, callback);
  },

  load(query, callback) {
    return get_data(this, '/backend/companies/' + company_id + '/selects/venues', query, callback);
  }
};

const FormFieldOptions = {
  preload: 'focus',
  load(query, callback) {
    const url = select_endpoint('/form_fields');
    return get_data(this, url, query, callback);
  }
};

const ReportTemplateOptions = {
  create(name, callback) {
    const type = this.$input.data('type');
    const url = '/company/' + company_id + '/report_templates/new';
    const params = {
      report_template: {
        name,
        type
      }
    };
    return get_modal(url, params, callback);
  },
  load(query, callback) {
    const type = this.$input.data('type');
    return get_data(this, null, query, callback, {type});
  }
};

const EventPageOptions = {};

const VariantOptions = {
  template: 'no-img user',
  labelField: 'name',
  searchField: ['name', 'description'],
  render: {
    option(variant, escape) {
      let html = '';
      html += '<div>';
      html += '<span class="title">';
      html += '<span class="name">' + escape(variant.name) + '</span>';
      html += '<span>' + escape(variant.display_price) + '</span>';
      html += '</span>';

      html += '<span class="description">';
      html += escape(variant.description);
      html += '</span>';

      html += '</div>';
      return html;
    }
  },
  onChange(value) {
    if (!!!value) { return; }
    return this.$input.closest('.form-group').siblings('.form-group').find('input.stock-item-select').each(function(index, input) {
      if (input.selectize) {
        input.selectize.clearOptions();
        return input.selectize.onSearchChange('');
      }
    });
  },

  load(query, callback) {
    return get_data(this, null, query, callback);
  }
};

const CompanyOptions = {
  preload: false,
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    return get_data(this, '/api/v2/companies', query, callback);
  }
};

const FormOptions = {
  load(query, callback) {
    return get_data(this, select_endpoint('/forms'), query, callback);
  }
};

const EventFormOptions = {
  labelField: 'link_name',
  searchField: ['link_name'],
  render: {
    option(item, escape) {
      let html = '';
      html += '<div>';
      html += '<span class="title">';
      html += '<span class="name">' + escape(item.name) + '</span>';
      html += '</span>';
      html += '<span class="description">';
      html += escape(item.link_name);
      html += '</span>';
      html += '</div>';
      return html;
    }
  }
};

var event_ids_for_input = function($input) {
  const el = $input.data('target');
  const $el = $input.parents('form').find(el);
  let event_ids = $el.val();
  if (!event_ids) { event_ids = $el.data('value'); }
  if (!event_ids || $el.hasClass('event-ticket-type-select')) { event_ids = $el.data('event-ids'); }
  if (!event_ids) { event_ids = $input.data('event-ids') }
  return event_ids;
};

const ListOptions = {
  create(name, callback) {

    const event_id = event_ids_for_input(this.$input);
    $.ajax({
      url: select_endpoint('/lists'),
      type: 'POST',
      data: {
        list: {
          name,
          event_id
        }
      },
      success(data) {
        return callback({id: data.id, name: data.name});
      }
    });
  },
  labelField: 'name',
  searchField: ['name'],
  preload: 'focus',
  load(query, callback) {

    const event_ids = event_ids_for_input(this.$input);
    let url = this.$input.data('url');
    if (!url) { url = select_endpoint('/lists/'); }
    const value = this.$input.data('value');
    const label_format = this.$input.data('label');
    return get_data(this, url, query, callback, {event_ids, label: label_format});
  }
};

const CheckoutCustomizationOptions = {
  preload: 'focus',
  create(name, callback) {
    const url = '/company/' + company_id + '/checkout_customizations/new';
    const params = {
      checkout_customization: {
        name
      }
    };
    return get_modal(url, params, callback);
  }
};

const CompanyUserOptions = {};

const ReturnItemOptions = {
  labelField: 'name',
  searchField: ['name']
};

const RsvpFormOptions = {
  labelField: 'name',
  searchField: ['name'],
  create: (name, callback) => {

    const url = '/company/' + company_id + '/rsvp_forms/new';
    const params = {
      rsvp_form: {
        name
      }
    };
    return get_modal(url, params, callback);
  }
};

const TicketTierLevelOptions = {
  template: 'no-img user',
  searchField: ['name'],
  labelField: ['name'],
  render: {
    option(registration_type, escape) {
      let html = '';
      html += '<div>';
      html += '<span class="title">';
      html += '<span class="name">' + escape(registration_type.name) + '</span>';
      html += '</span>';

      html += '<span class="description">';
      html += escape(registration_type.ticket_type);
      html += '</span>';

      const {
        status
      } = registration_type;
      const selling_class = status === 'selling' ? 'text-success' : 'text-danger';
      html += '<span class="description ' + selling_class + '">';
      html += escape(status);
      html += '</span>';

      html += '</div>';
      return html;
    }
  },
  load(query, callback) {
    const event_id = this.$input.data('event-id');
    return get_data(this, null, query, callback, {event_ids: event_id});
  }
};

const StockItemOptions = {
  preload: 'focus',
  load(query, callback) {
    if (!company_id) { return; }
    const el = this.$input.data('target');
    const $el = this.$input.closest('.form-group').siblings('.form-group').find(el);
    let variant_id = $el.val();
    if (!variant_id) { variant_id = $el.data('value'); }
    const value = this.$input.data('value');
    let url = this.$input.data('url');
    if (!url) { url = select_endpoint('/stock_items'); }
    return get_data(this, url, query, callback, {variant_id});
  }
};

const StockLocationOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/stock_locations');
    return get_data(this, url, query, callback);
  }
};

const PromotionOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/promotions');
    return get_data(this, url, query, callback);
  }
};

const ReimbursementTypeOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/reimbursement_types');
    return get_data(this, url, query, callback);
  }
};

const ReturnAuthorizationReasonOptions = {
  preload: 'focus',
  labelField: 'name',
  searchField: ['name'],
  load(query, callback) {
    const url = select_endpoint('/return_authorization_reasons');
    return get_data(this, url, query, callback);
  }
};

const PaymentMethodSelectOptions = {
  load(query, callback) {
    let url = this.$input.data('url');
    if (!url) { url = select_endpoint('/payment_methods'); }
    return get_data(this, url, query, callback);
  }
};

const EmailTemplateOptions = {
  create(name, callback) {
    let url = '/company/' + company_id + '/email_templates/new';
    if (this.$input.data('disable-sections')) { url += '?disable_sections=1'; }
    const params = {
      email_template: {
        name
      }
    };
    return get_modal(url, params, callback);
  },
  load(query, callback) {
    const url = select_endpoint('/email_templates');
    return get_data(this, url, query, callback);
  }
};

const CampaignOptions = {
  load(query, callback) {
    const url = select_endpoint('/campaigns');
    return get_data(this, url, query, callback);
  }
};

const ContactFieldOptions = {
  create(name, callback) {
    const url = '/company/' + company_id + '/contact_fields/new';
    const params = {
      contact_field: {
        name
      }
    };
    return get_modal(url, params, callback);
  },
  onChange(value) {
    if (!!!value) { return; }
    const {
      $input
    } = this;
    const $inputs = $input.closest('.contact-field-group').find('input.contact-field-select');
    const control = this;
    return $inputs.each(function(index, select) {
      const $select = $(select);
      if (($input[0] !== select) && ($select.val() === value)) {
        notify('info', 'That field has already been added.');
        control.setValue(null);
        return;
      }
    });
  },
  load(query, callback) {
    const url = select_endpoint('/contact_fields');
    return get_data(this, url, query, callback);
  }
};

const merge_options = options => $.extend({}, default_options, options);

const functions = {
  'Selects': {
    'EventOptions': merge_options(EventOptions),
    'DeviceOptions': merge_options(DeviceOptions),
    'EmployeeOptions': merge_options(EmployeeOptions),
    'RsvpFormOptions': merge_options(RsvpFormOptions),
    'ContactOptions': merge_options(ContactOptions),
    'CampaignOptions': merge_options(CampaignOptions),
    'ListOptions': merge_options(ListOptions),
    'PaymentMethodSelectOptions': merge_options(PaymentMethodSelectOptions),
    'ContactFieldOptions': merge_options(ContactFieldOptions),
    'MembershipPlanOptions': merge_options(MembershipPlanOptions),
    'TicketTypeOptions': merge_options(TicketTypeOptions),
    'TicketTemplateOptions': merge_options(TicketTemplateOptions),
    'TallyTypeOptions': merge_options(TallyTypeOptions),
    'VariantOptions': merge_options(VariantOptions),
    'ProductOptions': merge_options(ProductOptions),
    'TallyTemplateOptions': merge_options(TallyTemplateOptions),
    'PickupTemplateOptions': merge_options(PickupTemplateOptions),
    'StationTemplateOptions': merge_options(StationTemplateOptions),
    'TicketFieldOptions': merge_options(TicketFieldOptions),
    'CompanyOptions': merge_options(CompanyOptions),
    'TagOptions': merge_options(TagOptions),
    'ContactQueryOptions': merge_options(ContactQueryOptions),
    'ImportOptions': merge_options(ImportOptions),
    'EventFormOptions': merge_options(EventFormOptions),
    'EmailTemplateOptions': merge_options(EmailTemplateOptions),
    'VenueOptions': merge_options(VenueOptions),
    'FormOptions': merge_options(FormOptions),
    'TaxonOptions': merge_options(TaxonOptions),
    'OptionTypeOptions': merge_options(OptionTypeOptions),
    'OptionValueOptions': merge_options(OptionValueOptions),
    'PermanentListOptions': merge_options(PermanentListOptions),
    'ListSettingOptions': merge_options(ListSettingOptions),
    'OrderFormOptions': merge_options(OrderFormOptions),
    'EventTicketTypeOptions': merge_options(EventTicketTypeOptions),
    'EventPageOptions': merge_options(EventPageOptions),
    'SitePageOptions': merge_options(SitePageOptions),
    'RefundReasonOptions': merge_options(RefundReasonOptions),
    'ReturnAuthorizationReasonOptions': merge_options(ReturnAuthorizationReasonOptions),
    'StockLocationOptions': merge_options(StockLocationOptions),
    'ReturnItemOptions': merge_options(ReturnItemOptions),
    'ReimbursementTypeOptions': merge_options(ReimbursementTypeOptions),
    'ZoneOptions': merge_options(ZoneOptions),
    'TaxCategoryOptions': merge_options(TaxCategoryOptions),
    'ShippingStateOptions': merge_options(ShippingStateOptions),
    'ShippingCountryOptions': merge_options(ShippingCountryOptions),
    'OrderChannelOptions': merge_options(OrderChannelOptions),
    'TaxRateOptions': merge_options(TaxRateOptions),
    'PassbookTemplateOptions': merge_options(PassbookTemplateOptions),
    'ServiceFeeCategoryOptions': merge_options(ServiceFeeCategoryOptions),
    'ServiceFeeRateOptions': merge_options(ServiceFeeRateOptions),
    'SeatChartOptions': merge_options(SeatChartOptions),
    'TicketOptions' : merge_options(TicketOptions),
    'EventSeatOptions' : merge_options(EventSeatOptions),
    'CheckoutCustomizationOptions': merge_options(CheckoutCustomizationOptions),
    'StockItemOptions': merge_options(StockItemOptions),
    'PromotionOptions': merge_options(PromotionOptions),
    'CompanyUserOptions': merge_options(CompanyUserOptions),
    'PaymentOptions': merge_options(PaymentOptions),
    'CategoryOptions': merge_options(CategoryOptions),
    'CalendarOptions': merge_options(CalendarOptions),
    'EventSessionOptions' : merge_options(EventSessionOptions),
    'TicketFieldOptionOptions': merge_options(TicketFieldOptionOptions),
    'ReportTemplateOptions': merge_options(ReportTemplateOptions),
    'FormFieldOptions': merge_options(FormFieldOptions),
    'TicketTierLevelOptions': merge_options(TicketTierLevelOptions),
    'EmailLayoutTemplateOptions': merge_options(EmailLayoutTemplateOptions),
    'StationOptions': merge_options(StationOptions),
    'CheckinActionTemplateOptions': merge_options(CheckinActionTemplateOptions)
  }
};

$.extend(true, window, functions);

window.add_all_selectize = $container => (() => {
  const result = [];
  for (let select of Array.from(selects)) {
    const selector = 'input.' + select[0];
    const $selections = $container.find(selector).not('.selectized');
    if ($selections.length) {
      result.push(add_selectize($selections, $.extend(true, {}, select[1])));
    } else {
      result.push(undefined);
    }
  }
  return result;
})();

var add_selectize = ($inputs, options) => $inputs.each(function(index, input) {
  const $input = $(input);
  if (!input.selectize) {
    const max = $input.data('maxitems');
    let value = $input.data('value');
    const create = $input.data('create');
    if (max) { options.maxItems = max; }
    if (max === 0) { options.maxItems = null; }
    const preload = $input.data('preload');
    if (value) {
      options.preload = true;
    } else if (typeof preload !== 'undefined') {
      options.preload = preload;
    }
    if ((create === false) || (create === 'false')) { options.create = false; }
    options.onItemRemove = function(value, $item) {
      this.$input.removeData('value');
      return this.$input.removeAttr('data-value');
    };
    options.onLoad = function(data) {
      value = this.$input.data('value');
      const current_value = this.getValue();
      if (value && !current_value && (value !== current_value) && !( (value.length === 0) && (current_value === ''))) {
        return this.setValue(value);
      }
    };
    const {
      template
    } = options;
    if (template) {
      delete options.template;
      $input.addClass(template);
    }
    $input.selectize(options);
    if ($input.data('disabled')) {
      return input.selectize.disable();
    }
  }
});

var selects = [
  ['campaign-select', Selects.CampaignOptions],
  ['station-select', Selects.StationOptions],
  ['rsvp-form-select', Selects.RsvpFormOptions],
  ['device-select', Selects.DeviceOptions],
  ['employee-select', Selects.EmployeeOptions],
  ['list-select', Selects.ListOptions],
  ['membership-plan-select', Selects.MembershipPlanOptions],
  ['event-select', Selects.EventOptions],
  ['company-select', Selects.CompanyOptions],
  ['ticket-type-select', Selects.TicketTypeOptions],
  ['comp-type-select', Selects.TallyTypeOptions],
  ['variant-select', Selects.VariantOptions],
  ['product-select', Selects.ProductOptions],
  ['ticket-field-select', Selects.TicketFieldOptions],
  ['contact-field-select', Selects.ContactFieldOptions],
  ['contact-select', Selects.ContactOptions],
  ['tag-select', Selects.TagOptions],
  ['contact-query-select', Selects.ContactQueryOptions],
  ['import-select', Selects.ImportOptions],
  ['event-form-select', Selects.EventFormOptions],
  ['form-select', Selects.FormOptions],
  ['email-template-select', Selects.EmailTemplateOptions],
  ['payment-method-select', Selects.PaymentMethodSelectOptions],
  ['venue-select', Selects.VenueOptions],
  ['option-type-select', Selects.OptionTypeOptions],
  ['option-value-select', Selects.OptionValueOptions],
  ['taxon-select', Selects.TaxonOptions],
  ['permanent-list-select', Selects.PermanentListOptions],
  ['comp-type-template-select', Selects.TallyTemplateOptions],
  ['pickup-template-select', Selects.PickupTemplateOptions],
  ['station-template-select', Selects.StationTemplateOptions],
  ['order-form-select', Selects.OrderFormOptions],
  ['event-ticket-type-select', Selects.EventTicketTypeOptions],
  ['event-page-select', Selects.EventPageOptions],
  ['site-page-select', Selects.SitePageOptions],
  ['refund-reason-select', Selects.RefundReasonOptions],
  ['return-authorization-reason-select', Selects.ReturnAuthorizationReasonOptions],
  ['stock-location-select', Selects.StockLocationOptions],
  ['return-item-select', Selects.ReturnItemOptions],
  ['reimbursement-type-select', Selects.ReimbursementTypeOptions],
  ['zone-select', Selects.ZoneOptions],
  ['tax-category-select', Selects.TaxCategoryOptions],
  ['shipping-country-select', Selects.ShippingCountryOptions],
  ['shipping-state-select', Selects.ShippingStateOptions],
  ['order-channel-select', Selects.OrderChannelOptions],
  ['tax-rate-select', Selects.TaxRateOptions],
  ['service-fee-rate-select', Selects.ServiceFeeRateOptions],
  ['service-fee-category-select', Selects.ServiceFeeCategoryOptions],
  ['seat-chart-select', Selects.SeatChartOptions],
  ['ticket-select', Selects.TicketOptions],
  ['event-seat-select', Selects.EventSeatOptions],
  ['checkout-customization-select', Selects.CheckoutCustomizationOptions],
  ['stock-item-select', Selects.StockItemOptions],
  ['promotion-select', Selects.PromotionOptions],
  ['company-user-select', Selects.CompanyUserOptions],
  ['payment-select', Selects.PaymentOptions],
  ['passbook-template-select', Selects.PassbookTemplateOptions],
  ['category-select', Selects.CategoryOptions],
  ['calendar-select', Selects.CalendarOptions],
  ['event-session-select', Selects.EventSessionOptions],
  ['ticket-template-select', Selects.TicketTemplateOptions],
  ['list-setting-select', Selects.ListSettingOptions],
  ['ticket-field-option-select', Selects.TicketFieldOptionOptions],
  ['report-template-select', Selects.ReportTemplateOptions],
  ['form-field-select', Selects.FormFieldOptions],
  ['ticket-tier-level-select', Selects.TicketTierLevelOptions],
  ['email-layout-template-select', Selects.EmailLayoutTemplateOptions],
  ['checkin-action-template-select', Selects.CheckinActionTemplateOptions]
];