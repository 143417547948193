import Rails from '@rails/ujs';
import { create_card_token } from 'app/frontend/js/checkout/payment.js';

const clear_card_token = $form => $form.find('input#card_token').val('');

$(document).on('ajax:beforeSend', '.new-payment-form', function(e) {
  const $form = $(this);
  const btn = $form.find('input[type="submit"]')[0];

  const source_required = ($form.find('.existing_source_id').length > 0) || ($form.find('input.cvv').length > 0);
  const existing_source_id = $form.find('select.existing_source_id:enabled').val();
  const card_token = $form.find('input#card_token').val();

  if (source_required && (!card_token && !existing_source_id)) {
    Rails.disableElement(btn);
    create_card_token($form, function(result) {
      Rails.enableElement(btn);
      if (result.error) {
        return notify('error', result.error);
      } else {
        add_card_data_to_form($form, result.customer);
        return Rails.handleRemote.call($form[0], e);
      }
    });

    return false;
  }
});

$(document).on('ajax:complete', '.new-payment-form', function() {
  return clear_card_token( $(this) );
}); // do this in case the post to /payments fails