import { convert_params, update_column_check_box } from './functions'

$.extend(true, $.fn.dataTable.defaults, {
  processing: true,
  serverSide: true,
  //responsive: false,
  autoWidth: false,
  scrollX: true,
  scrollY: true,
  rowId: (function(_this) {
    return function(record) {
      return 'id_' + record.id;
    };
  })(this),
  destroy: true,
  stateSave: true,
  //colReorder: true,
  stateDuration: 0,
  stateSaveCallback: function(settings, data) {
    delete data.start;
    return localStorage.setItem('DT_' + settings.sInstance, JSON.stringify(data));
  },
  stateLoadCallback: function(settings) {
    var json = localStorage.getItem('DT_' + settings.sInstance);
    var data = JSON.parse(json);
    if (data) {
      delete data.start;
    }
    return data;
  },
  stateLoaded: function(settings, data) {
    var table_id = this.data('id');
    var length = this.api().page.len();
    $('.dt-length-select[data-id="' + table_id + '"]').val(length);

    this.api().columns().every(function() {
      var link;
      link = $('.column-toggle-dropdown[data-id="' + table_id + '"]').find('.dropdown-menu a[data-column="' + this.index() + '"]');
      return update_column_check_box($(link), this.visible());
    });
  },
  ajax: {
    data: function(data, context) {

      var $table = $(context.nTable);
      var table_id = $table.data('id');
      var $search_form = $('form.dt-search-form[data-id="' + table_id + '"]');
      var json = $search_form.serializeJSON({
        skipFalsyValuesForTypes: ['string'],
        useIntKeysAsArrayIndex: true
      });
      delete json.authenticity_token;
      delete json.utf8;

      var params = convert_params(data, $table.DataTable());
      params.id = table_id;

      return {
        data_table: JSON.stringify(params),
        search: JSON.stringify(json)
      };
    }
  },
  language: {
    lengthMenu: '_MENU_'
  },
  preDrawCallback: function(settings) {
    var table_id = this.data('id');
    $('.dt-search-form[data-id="' + table_id + '"]').find('input[name="search"]').val( $('.dt-search-query[data-id="' + table_id + '"]').val() );

  },
  drawCallback: function(settings) {
    if (!settings.ajax) {
      return;
    }
    var $table = $(this);
    var table_id = $table.data('id');
    var $container = $(this.api().table().container());
    init_container($container);
    var $all_table_elements = $('[data-id="' + table_id + '"]')

    var $export_button = $all_table_elements.find('.export-button');
    var $badge = $export_button.find('span.export-count');
    var total_count = settings._iRecordsDisplay;
    var $select_all_container = $('.select-all-dropdown[data-id="' + table_id + '"]');
    $select_all_container.find('a[data-action="all"] span.badge').html(total_count);
    $select_all_container.find('a[data-action="page"] span.badge').html(settings._iDisplayLength);
    $badge.html(total_count);
    var $empty_view = $('.empty-view[data-id="' + table_id + '"]');
    if (total_count === 0) {
      $empty_view.addClass('d-flex');
      $container.addClass('d-none');
    } else {
      $empty_view.removeClass('d-flex');
      $container.removeClass('d-none');
    }

    var $filter_button_badge = $('.filter-button[data-id="' + $table.data('id') + '"]').find('span.badge')

    var params = this.DataTable().ajax.params();
    if (params) {
      // update export and bulk action form
      var $fields = $all_table_elements.filter('.export-form, .bulk-action-form, .summary-form, .table-view-form')
      var $params_field = $fields.find('input[name*="data_table"]:hidden');
      var $search_field = $fields.find('input[name*="search"]:hidden');

      $params_field.val(params.data_table);
      $search_field.val(params.search);

      // Eperiemnt
      $all_table_elements.filter('.related-form').find('.filter-field').val(params.search)
    }

    var $save_button = $('#save-dropdown[data-id="' + $table.data('id') + '"]');

    if (total_count !== settings._iRecordsTotal) {
      $filter_button_badge.html('ON')
      $save_button.removeAttr('disabled');
    } else {
      $filter_button_badge.html('');
      $save_button.attr('disabled', 'disabled')
    }
    return this.api().columns.adjust();
  },
  initComplete: function(settings, json) {
    var table_id = $(this).data('id');
    var $container = $(this.api().table().container());
    var $paging_field = $container.find('.table-paging');
    var $paging_container = $('.table-footer[data-id="' + table_id + '"]');
    $paging_container.html($paging_field.children());
    $paging_field.remove();
    $paging_container.find('.dt-paging').addClass('ml-lg-auto mt-2 mt-md-0');
  }
});