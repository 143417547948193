var force_hide_menu, update_search_attribute;

$(document).on('click', '.variant-input-controller .dropdown-menu a', function(event) {
  event.stopPropagation();
  if ($(this).data('content')) {
    $(this).popover({
      sanitize: false
    });
    return $(this).popover('toggle');
  }
});

force_hide_menu = false;

$(document).on('hide.bs.dropdown', '.variant-input-controller .input-group-append', function(event) {
  var clicked_element;
  clicked_element = event.delegateTarget.activeElement;
  if (!force_hide_menu && clicked_element !== event.relatedTarget) {
    return event.preventDefault();
  } else {
    return $(event.currentTarget).find('a').popover('hide');
  }
});

$(document).on('shown.bs.popover', '.variant-input-controller .dropdown-menu a', function(event) {
  var popover;
  popover = $(this).data('bs.popover').tip;
  $(this).closest('.variant-input-controller')[0].setAttribute('data-popover', $(popover).attr('id'));
  return init_container($(popover));
});

$(document).on('submit', 'form.variant-event-search-form', function(event) {
  var $variant_select, popover_id, selectize, variant_controller, variant_id;
  event.preventDefault();
  variant_id = $(this).find('input[name="variant_id"]').val();
  popover_id = $(this).closest('.popover').attr('id');
  variant_controller = $('.variant-input-controller[data-popover="' + popover_id + '"]');
  $variant_select = variant_controller.find('input.variant-select');
  $variant_select.data('value', variant_id);
  selectize = $variant_select[0].selectize;
  selectize.clearOptions();
  selectize.onSearchChange('');
  force_hide_menu = true;
  variant_controller.find('.dropdown-menu').dropdown('toggle');
  return force_hide_menu = false;
});

$(document).on('change', 'form.variant-event-search-form input[name="variant_id"]', function(event) {
  return $(this).closest('form').find('input[type="submit"]').prop('disabled', $(this).val() === null);
});

update_search_attribute = function($input, attribute, value) {
  var selectize;
  $input.data(attribute, value);
  selectize = $input[0].selectize;
  if (value) {
    selectize.enable();
    selectize.clearOptions();
    return selectize.onSearchChange('');
  } else {
    return selectize.disable();
  }
};

$(document).on('change', 'form.variant-event-search-form input.event-session-select', function() {
  var $event_select, $venue_select, parent_event_id;
  parent_event_id = $(this).val();
  $venue_select = $(this).closest('form').find('input.venue-select');
  $event_select = $(this).closest('form').find('input.event-select');
  $event_select.data('parent-event-id', parent_event_id);
  return update_search_attribute($venue_select, 'parent-event-id', parent_event_id);
});

$(document).on('change', 'form.variant-event-search-form input.venue-select', function() {
  var $event_select, venue_id;
  venue_id = $(this).val();
  $event_select = $(this).closest('form').find('input.event-select');
  return update_search_attribute($event_select, 'venue-id', venue_id);
});

$(document).on('change', 'form.variant-event-search-form input.event-select', function() {
  var $form, $tier_select, event_id;
  event_id = $(this).val();
  $form = $(this).closest('form');
  $tier_select = $form.find('input.ticket-tier-level-select');
  return update_search_attribute($tier_select, 'event-id', event_id);
});

$(document).on('change', 'form.variant-event-search-form input.ticket-tier-level-select', function() {
  var $variant_input, selectize, value;
  selectize = $(this)[0].selectize;
  $variant_input = $(this).closest('form').find('input[name="variant_id"]');
  value = selectize.getValue();
  if (value) {
    $variant_input.val(selectize.options[value].variant_id);
  } else {
    $variant_input.val('');
  }
  return $variant_input.trigger('change');
});