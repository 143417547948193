export const fields = [
  {
    name: "",
    fields: {
      Name: "ticket.name",
      Email: "ticket.email",
      Status: "ticket.status",
      "PDF ticket link": "ticket.links.pdf",
      "Apple wallet link": "ticket.links.wallet",
      "Transfer link": "ticket.links.transfer",
      "Edit ticket link": "ticket.links.edit",
      Barcode: "ticket.barcode",
      "QR code": "ticket.qrcode",
      "Ticket type": "ticket.ticket_type.name",
      "Ticket type content": "ticket.ticket_type.content"
    }
  },
  {
    name: "Ticket type",
    fields: {
      "Start date": "ticket.ticket_type.starts_at",
      "End date": "ticket.ticket_type.ends_at"
    }
  },
  {
    name: "Event",
    fields: {
      "Event name": "ticket.event.name",
      "Event date": "ticket.event.date",
      "Event time": "ticket.event.time"
    }
  },
  {
    name: "Location",
    fields: {
      "Location name": "ticket.event.venue.name",
      "Location address": "ticket.event.venue.address.address1",
      "Loation city": "ticket.event.venue.address.city",
      "Location state": "ticket.event.venue.address.state",
      "Location country": "ticket.event.venue.address.country",
      "Location zipcode": "ticket.event.venue.address.zip"
    }
  }
];
