window.close_modal = () => $(VisibleModalID).modal('hide');

window.show_modal = function(id, html, callback_function = null) {

  const $container = $('#modal-containers');
  const modal_id = '#' + id;

  if ($(modal_id).is(':visible')) {
    $(modal_id).html($(html).children());
  } else if ($(modal_id).length) {
    $(modal_id).replaceWith(html);
  } else {
    $container.append(html);
  }

  if (!$(modal_id).is(':visible')) {
    $(modal_id).modal({
      backdrop: 'static'});
  }

  if ($(modal_id).find('form').length) {
    const cancel_button = '<a href="#" class="btn btn-danger" data-dismiss="modal">Cancel</a>';
    $(modal_id).find('.modal-footer').prepend(cancel_button);
  }
  window.VisibleModalID = modal_id;
  if (callback_function) {
    return callback_function($(modal_id));
  }
};

// close modal after successful save
// exclude actions that open other modals (e.g. selectize edit input addon)
$(document).on('ajax:success', '.modal form', function(e) {
  if (e.target.tagName === 'FORM') {
    $(this).parents('.modal').modal('hide');
  }
});

$(document).on('show.bs.modal', '.modal', function() {
  // simple modal management...
  const zIndex = 1030 + 2 + $('.modal:visible').length;
  $(this).css('z-index', zIndex);
  return setTimeout((function() {
    $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
  }), 0);
});

// Some modals we don't want to remove, like #importing-progress-modal
$(document).on('hidden.bs.modal', '.modal:not(".keep")', () => //$(@).remove() # to prevent name collisions from nav tabs opening other modal windows
$('.modal:visible').length && $(document.body).addClass('modal-open'));

$(document).on('shown.bs.modal', '.modal', function() {
  const $modal = $(this);
  return init_container($modal);
});
