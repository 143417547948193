import Bugsnag from "@bugsnag/js";

const { version } = require('../../../../package.json');
const releaseStage = process.env.RAILS_ENV;

const options = {
  apiKey: "9afd673d4f4ca1d0d877ddc2a6fc8359",
  releaseStage: releaseStage,
  appVersion: version,
  enabledReleaseStages: ["production"],
  autoTrackSessions: releaseStage == 'production'
};

if (releaseStage == "production") {
  Bugsnag.start(options);
}