export function mapFieldsToButtons(trix, menu, keys) {
  // var header = document.createElement("h6");
  // header.setAttribute("class", "dropdown-header");
  // header.innerText = name;
  // menu.appendChild(header);

  keys.forEach(function(key, index) {
    const { name, fields } = key;

    Object.keys(fields).forEach(function(field) {
      var button = document.createElement("button");
      button.setAttribute("type", "button");
      button.setAttribute("class", "dropdown-item");
      //button.setAttribute("data-trix-action", "x-insert-field");
      button.setAttribute("title", `Insert ${field} field`);
      button.setAttribute("tabindex", "-1");
      button.setAttribute("data-value", fields[field]);
      button.innerText = field;

      button.addEventListener("click", function(event) {
        var value = button.getAttribute("data-value");
        var liquidValue = `{{${value}}}`;

        if (
          value == "barcode" ||
          value == "ticket.barcode" ||
          value == "qr_barcode" ||
          value == "ticket.qrcode"
        ) {
          var type =
            value === "barcode" || value === "ticket.barcode"
              ? "barcode"
              : "qrcode";
          var imageTag = buildBarcodeImageTag(type, liquidValue);

          trix.editor.insertHTML(imageTag.outerHTML);
        } else if (value.match(/links/)) {
          var linkTag = document.createElement("a");
          linkTag.setAttribute("href", liquidValue);
          if (value.match(/pdf/)) {
            linkTag.innerText = "Download ticket (PDF)";

          } else if (value.match(/wallet/)) {
            linkTag.innerText = "Download ticket (Apple Wallet)";

          } else if (value.match(/transfer/)) {
            linkTag.innerText = "Transfer ticket";

          } else if (value.match(/edit/)) {
            linkTag.innerText = "Edit ticket";
          }
          trix.editor.insertHTML(linkTag.outerHTML);
        } else {
          trix.editor.insertString(liquidValue);
        }
      });
      menu.appendChild(button);
    });

    if (index != keys.length - 1) {
      var divider = document.createElement("div");
      divider.setAttribute("class", "dropdown-divider");
      menu.appendChild(divider);
    }
  });
}

function buildBarcodeImageTag(type, value) {
  var tag = document.createElement("img");
  var barcodeValue = value.match(/ticket/) ? "ticket.barcode" : "barcode";
  tag.setAttribute(
    "src",
    `${window.location.origin}/${type}/{{${barcodeValue}}}`
  );

  return tag;
}
