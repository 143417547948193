const session_toggle = 'input.sessions-toggle';

const toggle_date_or_duration = function($input) {
  var $date_inputs, $dates, $durations, $form, $table, value;
  value = $input.val();
  $form = $input.closest('form');
  $table = $form.find('.cocoon-session_date_forms');
  $durations = $table.find('.duration-field');
  $dates = $table.find('.date-field');
  $date_inputs = $dates.find('input');
  if (value === 'date') {
    $dates.removeClass('d-none');
    $durations.addClass('d-none');
    $date_inputs.data('parsley-required', true);
    $durations.data('parsley-required', false);
    $date_inputs.attr('required', true);
    $durations.attr('required', false);
  } else {
    $dates.addClass('d-none');
    $durations.removeClass('d-none');
    $date_inputs.attr('required', false);
    $date_inputs.data('parsley-required', false);
    $durations.data('parsley-required', true);
    $durations.attr('required', true);
  }
  rebuild_parsley($form);
};

$(document).on('change', session_toggle, function(event) {
  toggle_date_or_duration($(this));
});

$(document).on('cocoon:after-insert', '.cocoon-session_date_forms', function(e, $inserted_item) {
  toggle_date_or_duration($(session_toggle + ':checked'));
});

const update_named_grouping = function($field) {
  var $all_tiers, $container;
  $container = $field.closest('.cocoon-ticket_forms');
  $all_tiers = $container.find('.nested-fields');
  $all_tiers.each(function(index, tier) {
    var $checkbox, $matching_tiers, $ticket_type_select, $tier, event_id, names, query, value;
    $tier = $(tier);
    $ticket_type_select = $tier.find('input.ticket-type-select');
    if (!$ticket_type_select.length) {
      return;
    }
    value = $ticket_type_select.val();
    $checkbox = $tier.find('.new-product-checkbox');
    $matching_tiers = $all_tiers.not($tier).filter(function(index) {
      return value && $(this).find('.ticket-type-select[value="' + value + '"]').length > 0;
    });
    query = $matching_tiers.find('input.ticket-tier-name').map(function(index, field) {
      var name;
      name = $(field).val();
      if (!name) {
        name = $(field).data('unnamed');
      }
      return name;
    });
    names = query.get();
    event_id = $field.closest('form').find('input.event-session-select').val();
    return $.get({
      url: '/company/' + window.company_id + '/session_forms/tiers',
      data: {
        event_id: event_id,
        ticket_type_id: value
      },
      success: function(data) {
        var i, len;
        for (i = 0, len = data.length; i < len; i++) {
          tier = data[i];
          names.push(tier.name);
        }
        if (names.length) {
          $tier.find('span.group-with').html(names.join(', '));
          return $checkbox.addClass('d-block');
        } else {
          return $checkbox.removeClass('d-block');
        }
      }
    });
  });
};

$(document).on('change', '.sessions-form input.ticket-type-select', function() {
  var $field;
  $field = $(this).closest('.nested-fields');
  update_named_grouping($field);
});

$(document).on('change', '.sessions-form input.ticket-tier-name', function() {
  var $field;
  $field = $(this).closest('.nested-fields');
  update_named_grouping($field);
});

$(document).on('click', '.price-field-radio a', function(e) {
  var $parent, currency;
  e.preventDefault();
  currency = $(this).data('value');
  $parent = $(this).closest('.price-field-radio');
  $parent.find('input[type="hidden"]').val(currency);
  $parent.find('button').html(currency);
});