$(document).on('shown.bs.tab', '.event-campaign-template-fields', function(e) {
  const $previous_tab = $(e.relatedTarget);
  const $current_tab = $(e.target);
  toggle_required_fields($previous_tab, false);
  toggle_required_fields($current_tab, true);
  rebuild_parsley($previous_tab.closest('form'));
});

$(document).on('change', '.toggle-form-fields-required', function(e) {

  const required = this.checked;
  const target = $( $(this).data('target') );

  toggle_field_required(target, required)
  rebuild_parsley($(this).closest('form'));
});

var toggle_required_fields = function($link, active) {
  const $container = $( $link.attr('href') );
  toggle_field_required($container, active)
};

var toggle_field_required = function($container, required) {
  const $required_fields = $container.find('input[required],select[required],textarea[required],input[data-parsley-required],select[data-parsley-required]');

  for (const field of $required_fields) {

    field.dataset.wasRequired = true
  }

  //$required_fields.data('was-required', true);

  if (required) {
    $container.find('input[data-was-required],select[data-was-required]').attr('required', true).data('parsley-required', true);
  } else {
    $required_fields.removeAttr('required');
    $required_fields.removeAttr('data-parsley-required');
    $required_fields.removeData('parsley-required');
  }
}

const init_form = function() {
  toggle_required_fields($('.event-campaign-template-fields a.active'), true);
  toggle_required_fields($('.event-campaign-template-fields a:not(.active)'), false);
  rebuild_parsley($('.event-campaign-template-fields').closest('form'));

  const checkbox = $('[data-target=".email-fields"]');

  if (checkbox.length) {
    toggle_field_required($('.email-fields'), checkbox[0].checked);
  }
};


$(document).on('cocoon:after-insert', 'form.design-event-campaign-form', init_form)
$(document).on('cocoon:after-insert', 'form.checkin-action-template-form', init_form)

$(document).on('shown.bs.modal', init_form);

$(document).ready(init_form);