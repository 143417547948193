import "app/backend/backend.scss";
import "jquery-minicolors/jquery.minicolors.css";
import "app/base/application.js";

window.Trix = require("trix");
require("@rails/actiontext");

require("app/channels");

const flatpickr = require("flatpickr");

require("app/libraries/selectize");
require("app/libraries/parsley.js");
require("jquery-serializejson/jquery.serializejson");

require("datatables.net");
require("datatables.net-bs4");
// require("datatables.net-colreorder");
// require("datatables.net-colreorder-bs4");
// require("datatables.net-responsive");
// require("datatables.net-responsive-bs4");
require("datatables.net-select");
require("datatables.net-select-bs4");

require("intl-tel-input");

//require("chartkick/chart.js")
import "chartkick/chart.js"
require("jquery-minicolors");
const images = require.context("../app/images", true);

require("app/backend/js/app.js");
require("app/backend/js/data_tables/functions.js");
require("app/backend/js/data_tables/options.js");
require("app/backend/js/data_tables/event_listeners.js");
require("app/shared/general.js");
require("app/backend/js/campaigns.js");
require("app/backend/js/cocoon.js");
require("app/backend/js/email_templates.js");
require("app/backend/js/event_campaigns.js");
require("app/backend/js/event_designer.js");
require("app/backend/js/event_helpers.js");
require("app/backend/js/event_recurrences.js");
require("app/backend/js/events.js");
require("app/backend/js/general.js");
require("app/backend/js/imports.js");
require("app/backend/js/liquid.js");
require("app/backend/js/modals.js");
require("app/backend/js/orders.js");
require("app/backend/js/selects.js");
require("app/backend/js/stock_movements.js");
require("app/backend/js/theme.js");
require("app/backend/js/forms/exchange_form.js");
require("app/backend/js/forms/sessions.js");
require("app/backend/js/forms/variant_event_search.js");
require("app/backend/js/forms/export_template_form.js");
require("app/shared/shared_helpers.js");
require("app/backend/js/trix");