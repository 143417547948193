const update_calendar_schedule_preview = (params, $parent) => $.get({
  url: '/company/' + window.company_id + '/event_recurrences/summary',
  data: params,
  success(data) {
    const $summary = $parent.find('.event-series-summary');
    const $preview = $parent.find('.event-series-preview');

    const $summary_cell = $parent.find('.summary-cell');
    const $preview_cell = $parent.find('.events-cell');

    $preview.html('');
    $summary.html('');

    $summary_cell.addClass('text-success');
    $preview_cell.addClass('text-success');

    $summary.html(data.summary);
    $summary_cell.html(data.summary);

    if (data.dates) {
      $preview_cell.html(data.dates.length);
      return (() => {
        const result = [];
        for (let date of Array.from(data.dates)) {
          $preview.append(date.start + ' to ' + date.end);
          result.push($preview.append('<br />'));
        }
        return result;
      })();
    }
  }
});

const nested_form_handler = function(event) {
  const $input = $(this);
  const name = $input.attr('name');
  const intValue = parseInt(name.match(/[0-9]+/)[0], 10);

  const $inputs = $(':input[name*=' + intValue + ']');
  const params = $inputs.serializeArray();
  const $form = $input.closest('form');
  const event_id = $form.find('input.event-session-select').val();
  const venue_id = $form.find('input.venue-select').val();
  const form_key = name.split('[')[0]
  const nested_field_attribute = form_key + '[recurrences_attributes][' + intValue + ']';
  params.push({
    name: nested_field_attribute + '[venue_id]',
    value: venue_id
  });
  params.push({
    name: nested_field_attribute + '[event_id]',
    value: event_id
  });
  params.push({
    name: 'time_zone',
    value: $form.find('input[name="time_zone"]').val()
  });

  update_calendar_schedule_preview(params, $input.closest('.nested-fields'));
};

// ensure the associated field is completed for the selected end setting
$(document).on('change', 'input.ends-radio', function() {
  const $radio = $(this);
  const $panel = $radio.closest('.recurrence-end-panel');
  $panel.find('input').not('.ends-radio').addClass('never-validate').prop('required', false);
  $radio.closest('.form-group').find('input').not('.ends-radio').removeClass('never-validate').prop('required', true);
});

$(document).on('change change.datetimepicker', ':input[name*="recurrences_attributes"]', nested_form_handler);
// picks up datetimepicker changes
$(document).on('blur', '.flatpickr input[name*="recurrences_attributes"]', nested_form_handler);

$(document).on('change', 'form#new_event_recurrence', function(event) {
  const $form = $(this);
  const params = $form.serializeArray();
  update_calendar_schedule_preview(params, $form);
});

$(document).on('change', 'input.month-mode', function() {
  const $form = $(this).closest('form');
  const $target_fields = $form.find( $(this).data('target') );
  $form.find('.month-fields > div').addClass('d-none');
  $form.find('.month-fields input').prop('required', false);
  $target_fields.removeClass('d-none');
  $target_fields.find('input').prop('required', true);
});

const recurrences_ready = () => // refresh  when the form is rendered during invalid creation
// not sure why timeout is needed
setTimeout(() => $('select.time-select').trigger('change'), 1000);

$(recurrences_ready);