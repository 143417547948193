import { create_tables } from './data_tables/functions';

window.VisibleModalID = null;

window.init_container = function($container) {

  if (!$container) { $container = $('body'); }
  init_phones($container);
  init_datetimepickers($container);
  general_init($container);
  setup_cocoon($container);
  init_color_pickers($container);
  add_selectize_fields($container);
  add_all_selectize($container);
  create_tables($container);
};

window.resource_updated = function(name) {
  notify('success', name);
  TableRefreshVisible();
};

window.update_time_zone_inputs = ($form, time_zone) => $form.find('small.current-time-zone').html(time_zone.substring(0, 10) + '...');

$(document).on('inserted.bs.popover', '[data-toggle="popover"]', event => init_container($('.popover')));

$(document).on('shown.bs.collapse', function(e, data ) {
  const $href = $(e.target);
  init_container($href);
});

$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function(e) {
  const $href = $( $(e.target).attr('href') );
  init_container($href);
});

var init_datetimepickers = function($container) {

  const $pickers = $container.find('.flatpickr');
  $pickers.flatpickr();

};

var general_init = function($container) {
  $container.find('[data-toggle="tooltip"], .trigger-tooltip').tooltip({trigger: 'hover'});
  $container.find('[data-toggle="popover"]').popover({sanitize: false});
  $container.find('.tooltip-fixed').tooltip({trigger: 'manual'}).tooltip('show');

  const $forms = $container.find('form');
  if ($forms.length) { return $forms.parsley(); }
};

$(document).on('click', 'ul.dropdown-select li a', function(e) {
  e.preventDefault();
  const $a = $(this);
  const value = $a.data('value');
  const $btn_group = $a.closest('.input-group-btn');
  $btn_group.find('.dropdown-toggle').html(value + ' <span class="caret"></span>');
  $btn_group.find('input[type="hidden"]').val(value);
});

window.TableRefreshVisible = function() {
  let $container = null;

  const $modal = $('.modal.in:visible');
  if ($modal.length) {
    $container = $modal;
  } else {
    $container = $('body');
  }

  $.fn.dataTable.tables( { api: true} ).draw(false).columns.adjust();
};

$(document).on('show.bs.tooltip', 'a[disabled=disabled]', function(e) {
  $('a[disabled=disabled]').not(this).tooltip('hide');
});

$(document).on('click', 'a[disabled=disabled]', function(e) {
  e.preventDefault();
  e.stopPropagation();
});

var init_color_pickers = function($container) {

  $container.find('input.color-picker').minicolors({
    theme: 'bootstrap'});

  $container.find('input.cp-opacity-input').minicolors({
    theme: 'bootstrap',
    opacity: true,
    change(hex, opacity) {
      const $group = $(this).closest('.form-group');
      $group.find('input.cp-opacity-css').val($(this).minicolors('rgbaString'));
      $group.find('input.cp-opacity').val(opacity);
    }
  });
};

window.show_flash_messages = function() {
  const flashes = window.flash;
  if (flashes) {
    Object.keys(flashes).forEach(function(key) {
      notify(key, flashes[key]);
      delete window.flash[key];});
  }
}

$(document).ready(function() {
  if (typeof ga !== 'undefined' && ga !== null) { ga('send', 'pageview'); }

  init_container();
  show_flash_messages();

  const $billing_modal = $('#billing-modal');
  if ($billing_modal.length) {
    $billing_modal.modal({
      backdrop: 'static'});
  }

  if (window.next_step_link) {
    $('input[name="next"]').val(next_step_link);
  }

  // Issue with turoblinks cache + pressing back. See also before-cache event above
  // $('.modal.show').modal({
  //   backdrop: 'static'});
});