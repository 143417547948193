$(document).on('click', '.toggle-designer-btn', function(e) {
  const chosen_design = $(this).attr('href');
  const $form = $(this).closest('form');
  const $panels = $form.find('.design-panel');

  $form.find('input.chosen_design').val($(this).data('value'));
  $panels.find('input,select').attr('disabled', true);
  $panels.addClass('d-none');
  $(chosen_design).removeClass('d-none');
  $(chosen_design).find('input,select').removeAttr('disabled');
  $(this).closest('.scrollable').animate({
    scrollTop: $(chosen_design).position().top});
  return false;
});