$.fn.removeClassRegex = function(regex) {
  $(this).removeClass((index, classes) => classes.split(/\s+/).filter(c => regex.test(c)).join(' '));
};

$(document).on('ajax:success', '.stock-movement-form', function(event) {
  const data = event.detail[0];
  updateStockItemInventoryCount(data);
});

window.updateStockItemInventoryCount = function(data) {
  const $tr = $('tr.stock_item[data-id="' + data.stock_item_id + '"]');

  const $td_total = $tr.find('td.total-count');
  const $td_available = $tr.find('td.available-count');
  $td_total.html(data.total_count);
  $td_available.html(data.available_count);
  $tr.removeClassRegex(/^text-/);
  $tr.addClass(data.selling_class);
};