export const fields = [
  {
    name: "",
    fields: {
      Name: "name",
      "First name": "first_name",
      "Last name": "last_name",
      Barcode: "barcode",
      "QR code": "qr_barcode"
    }
  }
];
