window.update_table_row = function(table_id, status, data) {
  var $row, $table, id, table;
  id = data.id;
  if (!id) {
    return;
  }
  var row_id = "#id_" + id

  $table = $('table[data-id="' + table_id + '"]');
  table = $table.DataTable();

  var row = table.row(row_id)

  var node = row.node()

  if (!row.length || !node) {
    row = table.row.add(data)
    table.draw()
  }

  $row = $( node )

  if (status === 200) {
    row.data(data);
    $row.addClass("row-updated");
    setTimeout(function() {
      $row.removeClass("row-updated");
    }, 500);
    return init_container($row);
  } else if (status === 204 || status === 202) {
    row.remove().draw(false);
  }
  if (data.message) {
    notify('success', data.message);
  }
};

const default_options = {
  dom: "tr" + "<'table-paging'ip>"
};

export function create_tables($container) {
  var local_options = {
    serverSide: false,
    processing: false,
    ajax: null
  };
  $container.find("table.datatable").DataTable(local_options);
  $container.find("table.local-datatable").each(function(index, table) {
    var options;
    options = $.extend({}, default_options, local_options);
    options = $.extend({}, options, $(table).data());
    return $(table).DataTable(options);
  });

  var $tables = $container
    .find('table.remote-datatable')
    .filter(":visible")
    .filter(function(index) {
      return !$.fn.DataTable.isDataTable($(this));
    });


  $tables.each(function(index, table) {
    var options;
    options = $.extend({}, default_options, {});
    options = $.extend({}, options, $(table).data());
    $(table).DataTable(options);
  });

}

export const get_visible_columns = function(table) {
  var column_names = table.columns().dataSrc().toArray();
  var column_visible = table.columns().visible().toArray();
  var column_mapping = column_names.map( (name, index) => ({ name: name, visible: column_visible[index] }))
  return column_mapping;
}

export function update_column_check_box($link, visible) {
  var icon;
  icon = $link.find('i');
  if (visible) {
    return icon.removeClass('invisible');
  } else {
    return icon.addClass('invisible');
  }
};

export function refresh_table($element) {
  var table;
  table = get_table($element);
  table.draw().columns.adjust();
};

export function toggle_actions($el, show) {
  var $dropdown = $el.find('.dropdown-toggle');
  var $parent = $el.find('.dropdown');

  if (show) {
    $dropdown.removeClass('disabled');
    $parent.tooltip('disable');
  } else {
    $dropdown.addClass('disabled');
    $parent.tooltip('enable');
  }
};

export function get_table($from_element) {
  var table_id;
  table_id = $from_element.data('id');
  return $('table[data-id="' + table_id + '"]').DataTable();
};

export function apply_selected_ids(table, none = false) {
  var ids = table.rows({
    selected: true
  }).data().map(function(record) {
    return record.id;
  }).toArray();
  if (none) { ids = '' };

  return $('[data-id="' + $(table.container()).find('table').data('id') + '"]').find('input[name="ids"]').val(ids);
};

export function convert_params(data, table) {
  return {
    draw: data.draw,
    limit: data.length,
    offset: data.start,
    order: data.order
  };
};
