$(document).on('change', 'form.ticket-exchange input.variant-select', function(e) {
  var $checkbox, $element, $form, $label, current_price, exchange_price, variant;
  $form = $(this).closest('form');
  variant = this.selectize.options[this.selectize.getValue()];
  if (!variant) {
    return;
  }
  exchange_price = parseFloat(variant.price);
  current_price = parseFloat($form.find('input.current-price').val());
  $element = $form.find('.form-group.charge-difference');
  $label = $element.find('span.label');
  $checkbox = $element.find('input');
  if (exchange_price !== current_price) {
    $checkbox.removeAttr('disabled');
    $element.removeClass('d-none');
    if (exchange_price > current_price) {
      return $label.html('Charge');
    } else {
      return $label.html('Refund');
    }
  } else {
    $checkbox.attr('disabled', true);
    return $element.addClass('d-none');
  }
});