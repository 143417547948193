const GetCardType = function(number) {
  // visa
  let re = new RegExp('^4');
  if (number.match(re) !== null) {
    return 'visa';
  }
  // Mastercard
  re = new RegExp('^5[1-5]');
  if (number.match(re) !== null) {
    return 'master';
  }
  // AMEX
  re = new RegExp('^3[47]');
  if (number.match(re) !== null) {
    return 'american_express';
  }
  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number.match(re) !== null) {
    return 'discover';
  }
  // Diners
  re = new RegExp('^36');
  if (number.match(re) !== null) {
    return 'diners_club';
  }
  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]');
  if (number.match(re) !== null) {
    return 'diners_club';
  }
  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])');
  if (number.match(re) !== null) {
    return 'jcb';
  }
  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
  if (number.match(re) !== null) {
    return 'visa';
  }
  return '';
};

window.add_card_data_to_form = function($form, customer) {
  $form.find('input#card_token').val(customer.card.token);
  $form.find('input#last_four').val(customer.card.last_four);
  $form.find('input#brand').val(customer.card.brand);
  return $form.find('.clear-it').val('');
};

export var create_card_token = ($form, callback) => {
  const number = $form.find('input.card_number').val().replace(/-/g, '');
  const last_four = number.substr(number.length - 4);

  let street = $form.find('input[name="billing_address"]').val();
  let city = $form.find('input[name="billing_city"]').val();
  let region = $form.find('input[name="billing_region"]').val();
  let country = $form.find('input[name="billing_country"]').val();
  let postal_code = $form.find('input[name="billing_postal_code"]').val();

  if (!street) {
    street = $form.find('#payment_order_attributes_bill_address_attributes_address1').val();
  }
  if (!city) {
    city = $form.find('#payment_order_attributes_bill_address_attributes_city').val();
  }
  if (!region) {
    region = $form.find('#payment_order_attributes_bill_address_attributes_state_id option:selected').data('abbr');
  }
  if (!country) {
    country = $form.find('#payment_order_attributes_bill_address_attributes_country_id option:selected').data('code');
  }
  if (!postal_code) {
    postal_code = $form.find('#payment_order_attributes_bill_address_attributes_zipcode').val();
  }

  const customer_info = {
    name: $form.find('input.cardholder_name').val(),
    order_id: $form.find('input[name="order_id"]').val(),
    payment_method_type: $form.find('input#payment_method_type').val(),
    card: {
      number,
      last_four,
      brand: GetCardType(number),
      exp_month: $form.find('select.expiry_month').val(),
      exp_year: $form.find('select.expiry_year').val(),
      cvc: $form.find('input.cvv').val()
    },
    address: {
      street,
      city,
      region,
      country,
      postal_code
    }
  };

  const tokenize_key = $form.find('input[name="tokenize_key"]').val();
  const tokenize_login = $form.find('input[name="tokenize_login"]').val();

  let error = null;
  let token = null;

  switch (customer_info.payment_method_type) {

    case 'Gateway::Beanstream':
      return getLegato(customer_info, legato => {

        if (legato.success) {
          ({
            token
          } = legato);
          customer_info.card.token = token;
        } else {
          error = legato.message;
          false;
        }
        return callback({customer: customer_info, error});
      });

    case 'Gateway::Braintree':
      //split = customer_info.name.split(' ')
      //first_name = split[0]
      //last_name = split[1..(split.length - 1)].join(' ')
      return braintree.client.create({ authorization: tokenize_key }, function(error, client) {
        if (error) {
          callback({customer: customer_info, error: error.code + ': ' + error.message});
          return;
        } else {
          client.request({
            endpoint: 'payment_methods/credit_cards',
            method: 'post',
            data: {
              creditCard: {
                number: customer_info.card.number,
                expirationMonth: customer_info.card.exp_month,
                expirationYear: customer_info.card.exp_year,
                cvv: customer_info.card.cvc,
                //firstName: first_name
                //lastName: last_name
                billingAddress: {
                  streetAddress: customer_info.address.street,
                  locality: customer_info.address.city,
                  postalCode:  customer_info.address.postal_code,
                  region: customer_info.address.region,
                  countryName: customer_info.address.country
                }
              }
            }
          }, function(err, response) {
            if (err) {
              err = err.code + ': ' + err.message;
            } else {
              token = response.creditCards[0].nonce;
              customer_info.card.token = token;
            }
            callback({customer: customer_info, error: err});

          });
          return;
        }
      });

    case 'Gateway::AuthorizeNet':

      var cardData = {
        fullName: customer_info.name,
        cardNumber: customer_info.card.number,
        cardCode: customer_info.card.cvc,
        month: customer_info.card.exp_month,
        year: customer_info.card.exp_year
      };
      var zip = customer_info.address.postal_code;
      if (zip) {
        cardData.zip = zip;
      }

      var secureData = {
        cardData,
        authData: {
          clientKey: tokenize_key,
          apiLoginID: tokenize_login
        }
      };

      window.authorize_response_handler = function(response) {
        if (response.messages.resultCode === 'Error') {
          error = '';
          let i = 0;
          while (i < response.messages.message.length) {
            const string = response.messages.message[i].code + ':' + response.messages.message[i].text;
            error += string;
            error += "\n";
            i++;
          }
        } else {
          const data = response.opaqueData;
          token = data.dataValue;
          customer_info.card.token = token;
        }
        return callback({customer: customer_info, error});
      };
      //TODO: non global function
      return Accept.dispatchData(secureData, 'authorize_response_handler');

    case 'Gateway::Stripe':
      var response_handler = function(status, response) {
        if (response.error) {
          error = response.error.message;
        } else {
          token = response.id;
          customer_info.card.token = token;
        }
        callback({customer: customer_info, error});
      };
      if ((typeof Stripe === 'undefined' || Stripe === null)) {
        alert('Error loading javascript in your web browser. Please enable and refresh.');
        return;
      }
      Stripe.setPublishableKey(tokenize_key);
      return Stripe.card.createToken({
        name: customer_info.name,
        number: customer_info.card.number,
        cvc: customer_info.card.cvc,
        exp_month: customer_info.card.exp_month,
        exp_year: customer_info.card.exp_year,
        address_line1: customer_info.address.street,
        address_city: customer_info.address.city,
        address_state: customer_info.address.region,
        address_zip: customer_info.address.postal_code,
        address_country: customer_info.address.country
      }, response_handler);

    case 'Gateway::Quickbooks':
      var quickbooks_info = {
        card: {
          name: customer_info.name,
          number: customer_info.card.number,
          expMonth: customer_info.card.exp_month,
          expYear: '20' + customer_info.card.exp_year,
          cvc: customer_info.card.cvc
        }
      };

      if (customer_info.address.street) {
        // Only add if present. Otherwise will error.
        quickbooks_info.card.address = {
          streetAddress: customer_info.address.street.substring(0, 29),
          city: customer_info.address.city,
          region: customer_info.address.region,
          country: customer_info.address.country,
          postalCode: customer_info.address.postal_code
        };
      }

      return intuit.ipp.payments.tokenize(tokenize_key, quickbooks_info, function(token, response) {
        if (token !== null) {
          customer_info.card.token = token;
        } else {
          error = response.message + ' ' + response.detail + '' + response.moreinfo;
        }
        return callback({customer: customer_info, error});
      });
    default:
      error = 'Unknown payment method.';
      callback({customer: customer_info, error});
  }
};

$(document).on('change', '#use_existing_card_yes', function() {
  const $form = $(this).closest('form');
  $form.parsley().destroy();
  $form.find('#payment-method-fields').addClass('d-none').find('input,select').prop('disabled', true);
  $form.find('#existing-cards').removeClass('d-none').find('input,select').prop('disabled', false);
  $form.parsley();
  return false;
});

$(document).on('change', '#use_existing_card_no', function() {
  const $form = $(this).closest('form');
  $form.parsley().destroy();
  $form.find('input.payment-profile-id').val(null);
  $form.find('#payment-method-fields').removeClass('d-none').find('input,select').prop('disabled', false);
  $form.find('#existing-cards').addClass('d-none').find('input,select').prop('disabled', true);
  $form.parsley();
  return false;
});

$(document).on('submit', 'form.interac', function(e) {
  e.preventDefault();
  const $form = $(this);
  const $btn = $form.find('input[type="submit"]:visible');
  const payment_method_id = $btn.data('id');
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: '/interac/redirect',
    data: {
      payment_method_id
    },
    success(data) {
      $('body', document).append(data['redirect'].substring(0,data['redirect'].length-52));
      document.getElementById('frmIOnline').submit();
    }
  });
});

$(document).on('submit', 'form.gateway', function(e) {
  e.preventDefault();
  const $form = $(this);
  const $btn = $form.find('input[type="submit"]:visible');
  $btn.data('name', $btn.val());

  if ($form.find('select.existing_source_id:visible').length) { // continue with execution of form
    $btn.prop('disabled', true);
    $btn.val('Processing...');
    $form[0].submit();
    return;
  }
  if (!$form.parsley().isValid()) { return false; }
  $btn.prop('disabled', true);
  $btn.val('Processing...');

  return create_card_token($form, function(result) {
    const {
      token
    } = result.customer.card;

    if (!token && !result.error) {
      result.error = 'Unexpected error creating card. Please refresh and try again or contact support.';
    }

    if (token) {
      add_card_data_to_form($form, result.customer);
      return $form[0].submit();
    } else {
      const {
        error
      } = result;
      const {
        order_id
      } = result.customer;

      $btn.prop('disabled', false);
      $btn.val( $btn.data('name') );
      alert(error);
    }
  });
});

$(document).on('shown.bs.tab', '.payment_method_option', function(e) {
  const $new_tab = $( $(e.target).attr('href') );
  const $form = $new_tab.find('form');
  if ($form.length) {
    if ($form.find('#use_existing_card_yes').length) {
      $form.find('#use_existing_card_yes').trigger('change');
    }
  }
});

const payment_ready = function() {
  if ($('.checkout-step-processing, .user-merging').length) {
    setTimeout(() => window.location.reload()
    , 2000);
  }
  $('.active.payment_method_option').trigger('shown.bs.tab');
  $('a.payment_method_option').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });
};

$(document).ready(payment_ready);