import Rails from "@rails/ujs";
import {
  toggle_actions,
  apply_selected_ids,
  refresh_table,
  update_column_check_box,
  get_table,
  get_visible_columns
} from './functions'

$(document).on("ajax:success", "a[data-table], form[data-table]", function(
  event
) {
  var detail = event.detail;
  var data = detail[0],
    xhr = detail[2];
  var table_id = $(this).data("table");

  update_table_row(table_id, xhr.status, data);
});

$(document).on('select.dt deselect.dt', function(e, dt, type, indexes) {
  if (!dt) {
    return;
  }
  var table = $(dt.table().container()).find('table');
  var $bulk_action_form = $('.bulk-action-form[data-id="' + table.data('id') + '"]');
  var selected_rows = dt.rows({
    selected: true
  }).data();
  toggle_actions($bulk_action_form, selected_rows.length > 0);
  apply_selected_ids(dt.table());
});

$(document).on('ajax:success', '.bulk-action-dropdown form', function() {
  $(this).closest('.dropdown').find('.dropdown-toggle').dropdown('toggle');
});

function select_rows(table, action) {

  var $bulk_action_form = $('.bulk-action-form[data-id="' + $(table.table().container()).find('table').data('id') + '"]');

  switch (action) {
    case 'all':
      table.rows().select();
      toggle_actions($bulk_action_form, true);
      apply_selected_ids(table.table(), true);
      break;
    case 'none':
      table.rows().deselect();
      toggle_actions($bulk_action_form, false);
      apply_selected_ids(table.table());
      break;
    case 'page':
      table.rows().select();
      toggle_actions($bulk_action_form, true);
      apply_selected_ids(table.table());
  }

}

$(document).on('click', '.select-all-dropdown a', function(e) {
  var action = $(this).data('action');
  var $dropdown = $(this).closest('.dropdown');

  var table = get_table($dropdown);

  select_rows(table, action);
});

$(document).on('keyup search', '.dt-search-query', function(e) {
  var table_id = $(this).data('id');
  var $form = $('form.dt-search-form[data-id="' + table_id + '"]');
  var input = $form.find('input[name="search"]');
  input.val($(this).val());
  if (e.which === 13 || e.type === 'search') {
    refresh_table($form);
  }
});

$(document).on('keyup', '.dt-search-form input[name="query"]', function() {
  var table = get_table($(this).closest('form'));
  table.search($(this).val()).draw();
});

$(document).on('click.bs.dropdown', '.toggle-columns-menu', function(e) {
  e.stopPropagation();
  return e.preventDefault();
});

$(document).on('change', '.dt-length-select', function() {
  var table = get_table($(this));
  table.page.len($(this).val()).draw();
});

$(document).on('click', '.toggle-column-btn', function(e) {
  var column, column_index, is_visible, table;
  table = get_table($(this).closest('.dropdown'));
  column_index = $(this).data('column');
  column = table.column(column_index);
  is_visible = !column.visible();
  update_column_check_box($(this), is_visible);
  column.visible(is_visible);
  //table.responsive.rebuild();
  return e.preventDefault();
});

$(document).on('click', '.toggle-column-action-btn', function(e) {
  var table = get_table($(this).closest('.dropdown'));
  var visible = $(this).data('action') == 'all'
  table.columns('.dd').visible(visible)
  //table.responsive.rebuild();
  update_column_check_box($('.toggle-column-btn'), visible);
  e.preventDefault();
});

$(document).on('submit', 'form.dt-search-form', function(e) {
  return e.preventDefault();
});

$(document).on('change', '.dt-search-form input, .dt-search-form select', function(e) {
  refresh_table($(this).closest('form'));
});

$(document).on('click', '.datatable-search-btn', function() {
  refresh_table($(this));
  return false;
});

$(document).on('submit', '.table-view-form', function() {
  var table = get_table( $(this) );
  var columns = get_visible_columns(table);

  $(this).find('input[name*="columns"]').val(JSON.stringify(columns));
});

$(document).on('click.bs.dropdown', '.dropdown-menu.keep-open', function(e) {
  e.stopPropagation()
});

//flatpickr prevent month changes from closing dropdown menu
$(document).on('hide.bs.dropdown', '.datatable-filter', function(e) {
  if (document.querySelector('.flatpickr-calendar.open')) {
    e.stopPropagation()
    e.preventDefault()
  }
});

$(document).on('click', 'button.export-dimension', function() {
  var dimension = $(this).data('dimension');
  var $form = $(this).closest('form');
  $form.find('input[name="export_dimension"]').val(dimension);
  $form.submit();
  Rails.disableElement($form.find('.export-button')[0]);
});

$(document).on('click', 'button.export-template', function() {
  var template_id = $(this).data('export-template-id');
  var $form = $(this).closest('form');
  $form.find('input[name="export_template_id"]').val(template_id);
  $form.find('input[name="export_option"]').val('')
  $form.find('input[name="export_dimension"]').val('');
  $form.submit();
  Rails.disableElement($form.find('.export-button')[0]);
});

$(document).on('click', 'button.export-option', function() {
  var option = $(this).data('option');
  var $form = $(this).closest('form');
  $form.find('input[name="export_dimension"]').val('');
  $form.find('input[name="export_option"]').val(option);

  var table = get_table($(this).closest('form'));

  var column_mapping = get_visible_columns(table);

  $form.find('input[name="columns"]').val(JSON.stringify(column_mapping));
  Rails.disableElement($form.find('.export-button')[0]);
  $form.submit();
});

$(document).on('click', 'button.bulk-action', function() {
  var bulk_action = $(this).data('action');
  var $form = $(this).closest('form');
  $form.find('input[name="bulk_action"]').val(bulk_action);
  Rails.disableElement($form.find('.bulk-action-button')[0]);
});

$(document).on('click', '.datatable-clear-btn', function() {
  var dom_id = $(this).data('id');
  var $search_form = $('form[data-id="' + dom_id + '"]');
  $search_form.find('input[name="search"]').val('');
  $('input.dt-search-query[data-id="' + dom_id + '"]').val('');
  $search_form.find('input,select').not('input[type="hidden"]').each(function(index, el) {
    var selectize;
    selectize = el.selectize;
    if (selectize) {
      return selectize.setValue(null);
    } else if ($(this).is('input[type="checkbox"]')) {
      return $(this).prop('checked', false);
    } else {
      return $(this).val('');
    }
  });
  select_rows(get_table($search_form), 'none');

  refresh_table($(this));
});