import Sortable from "sortablejs/Sortable";

// This ensures that the dropdown of link_to_add_association's receive the proper insertion node (see promotions/form re :promotion_actions)
window.setup_cocoon = function($container) {
  const insertion_attribute = 'association-insertion-method';
  $container.find('.cocoon-links').each(function(index, container) {
    const $links = $(container).find('a');
    $container = $(container).siblings('.cocoon-container');
    if (!$container.length) { return; }
    const id = Math.random().toString(36).substr(2, 9);
    const selector = '.cocoon-container[data-selector="' + id + '"]';
    $container.attr('data-selector', id);
    $links.data('association-insertion-node', selector);
    if ($links.data(insertion_attribute) == null) {
      return $links.data(insertion_attribute, 'append');
    }
  });

  return $('.sortable').each(function(index, el) {
    let sortable;
    return sortable = Sortable.create(el, {
      //filter: 'input'
      handle: '.grab-handle',
      ghostClass: 'bg-success',
      onEnd(event) {
        const {
          oldIndex
        } = event;
        const {
          newIndex
        } = event;
        if (oldIndex === newIndex) { return; }
        return update_cocoon_position($(event.to));
      }
    }
    );
  });
};

const toggle_cocoon_collapse = $nested_field => $nested_field.find('.collapse').collapse('toggle');
  // $collapse = $inserted_item.find('.collapse')
  // $collapse.collapse 'show'

$(document).on('click', 'a.cocoon-collapse', function(e) {
  toggle_cocoon_collapse($(this).closest('.nested-fields'));
  return e.preventDefault();
});

$(document).on('cocoon:after-insert', 'form', function(e, $inserted_item) {
  const $form = $inserted_item.closest('form');
  init_container($inserted_item);
  const time_zone = $form.find('input[name="time_zone"]').val();
  if (time_zone) {
    update_time_zone_inputs($form, time_zone);
  }

  rebuild_parsley($(this));
  update_cocoon_position($inserted_item.closest('.cocoon-container'));
  if (!$inserted_item.hasClass('cocoon-row')) { return toggle_cocoon_collapse($inserted_item); }
});

$(document).on('cocoon:after-remove', 'form', function(e, $inserted_item) {
  // ensure removed items are not validated in parsley
  $inserted_item.find('input,select').addClass('never-validate');
  return rebuild_parsley($(this));
});

var update_cocoon_position = $element => $element.find('.nested-fields').each(function(index, el) {
  const $input = $(el).find('input.order-input');
  return $input.val(index);
});