import Noty from "noty";
//import "noty/lib/noty";

window.notify = function(type, message, time = 1500) {
  new Noty({
    type: type,
    timeout: time,
    theme: "semanticui",
    layout: "topRight",
    text: message
  }).show();
};

$(document).on("click", "a.disabled", function(event) {
  event.preventDefault();
  return false;
});

$(document).on("click", ".clear-image-btn", function() {
  $(this)
    .siblings("img")
    .fadeOut();
  $(this)
    .closest(".form-group")
    .find('input[type="file"]')
    .val("")
    .attr("disabled", true);
  $(this)
    .closest(".form-group")
    .find('input[type="hidden"]')
    .val("1");
  $(this).fadeOut();
  return false;
});

export function field_required($inputs, required) {
  $inputs.data("parsley-required", required);
  $inputs.attr("required", required);
  if (required) {
    return $inputs.siblings("label").addClass("required");
  } else {
    return $inputs.siblings("label").removeClass("required");
  }
}
