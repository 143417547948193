/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const ua = window['navigator']['userAgent'] || window['navigator']['vendor'] || window['opera'];
if (/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/.test(ua)) {
  $('body').addClass('touch');
}
// fix z-index on ios safari
if (/iPhone|iPod|iPad/.test(ua)) {
  $(document, '.modal, .aside').on('shown.bs.modal', function(e) {
    const backDrop = $('.modal-backdrop');
    $(e.target).after($(backDrop));
  });
}

const path_without_query = path => path.split('?')[0];

const highlight_nav = (url, $nav, fuzzy) => $nav.find('a').filter(function() {
  let href = $(this).attr('href');
  if (href && href.length) { href = path_without_query(href); }
  if (fuzzy) {
    return url.startsWith(href);
  } else {
    return url === href;
  }
});

const init = function() {
  const url = path_without_query(window.location.pathname);
  $('[data-nav] .active').removeClass('active');
  $('[data-nav]').each(function(index, nav) {
    let items = highlight_nav(url, $(nav), false);
    if (!items.length) {
      items = highlight_nav(url, $(nav), true);
    }

    return items.addClass('active').parents('li').addClass('active');
  });

};

$(document).on('click', '[data-nav] a', function(e) {
  const $this = $(this);
  let $active = undefined;
  let $li = undefined;
  let $li_li = undefined;
  $li = $this.parent();
  $li_li = $li.parents('li');
  $active = $li.closest('[data-nav]').find('.active');
  $li_li.addClass('active');
  ($this.next().is('ul') && $li.toggleClass('active')) || $li.addClass('active');
  $active.not($li_li).not($li).removeClass('active');
  if ($this.attr('href') && ($this.attr('href') !== '')) {
    $(document).trigger('Nav:changed');
  }
});

$(document).ready(init);

$(document).on('click', '[data-toggle^="class"]', function(e) {
  const $target = $(this).parents($(this).data('parent')).find($(this).data('target'));
  const klass = $(this).data('toggle').split(':')[1];
  return $target.toggleClass(klass);
});

$(document).on('click', '[data-toggle^="class"]', function(e) {
  //e and e.preventDefault()
  let $this = $(e.target);
  let $class = undefined;
  let $target = undefined;
  let $tmp = undefined;
  let $classes = undefined;
  let $targets = undefined;
  !$this.data("toggle") && ($this = $this.closest("[data-toggle^=\"class\"]"));
  if ($this.data('parent')) { return; }
  $class = $this.data()["toggle"];
  $target = $this.data("target") || $this.attr("href");
  $class && ($tmp = $class.split(":")[1]) && ($classes = $tmp.split(","));
  $target && ($targets = $target.split(","));
  $targets && $targets.length && $.each($targets, (index, value) => ($targets[index] !== "#") && $($targets[index]).toggleClass($classes[index]));
  return $this.toggleClass("active");
});

$(window).on('resize', function() {
  const $w = $(window).width();
  const $lg = 1200;
  const $md = 991;
  const $sm = 768;
  if ($w > $lg) {
    $('.aside-lg').modal('hide');
  }
  if ($w > $md) {
    $('#aside').modal('hide');
    $('.aside-md, .aside-sm').modal('hide');
  }
  if ($w > $sm) {
    $('.aside-sm').modal('hide');
  }
});